import {BaseApiClient} from "./BaseApiClient";
import {requestHeaderForGET, requestHeaderForAnyGET} from "../../utilities/InternetSettings";

export class UserApiClient extends BaseApiClient {

    getPath() {
        return "users"
    }

    async getUserDetails(id) {
        return await this.instance.get(`/userinfo?id=${id}`, {
            headers: requestHeaderForGET(false),
            baseURL: this.getFullBaseUrl(),
            withCredentials: true,
            credentials: "include",
            mode: "cors",
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: null, error: error.response}
        });
    }

    async disableMFAUserAccount(email) {
        return await this.instance.get(`/v1/iam/users/disableMFAUserAccount/${email}`, {
            headers: requestHeaderForAnyGET(true),
            baseURL: this.getBaseUrl(),
            withCredentials: true,
            credentials: "include",
            mode: "cors",
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: null, error: error.response}
        });
    }

    async unLockUserAccount(email) {
        return await this.instance.get(`/v1/iam/users/unLockUserAccount/${email}`, {
            headers: requestHeaderForAnyGET(true),
            baseURL: this.getBaseUrl(),
            withCredentials: true,
            credentials: "include",
            mode: "cors",
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: null, error: error.response}
        });
    }

    async adminEmailVerify(email) {
        return await this.instance.get(`/v1/iam/users/adminEmailVerify/${email}`, {
            headers: requestHeaderForAnyGET(true),
            baseURL: this.getBaseUrl(),
            withCredentials: true,
            credentials: "include",
            mode: "cors",
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: null, error: error.response}
        });
    }

    async setUserPassword(payload) {
        return await this.instance.post(`/v1/iam/users/adminSetPassword`, payload, {
            headers: requestHeaderForAnyGET(true),
            baseURL: this.getBaseUrl(),
            withCredentials: true,
            credentials: "include",
            mode: "cors",
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: null, error: error.response}
        });
    }
}
