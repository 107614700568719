export const getUserFullName = store => {
    const {firstName, lastName} = store.session.user
    return firstName ? `${firstName} ${lastName}`.trim() : ""
}

export const getUserName = store => {
    const {email} = store.session.user
    return email
}

export const isLoggedIn = store => store.session.token !== undefined

export const getRefreshToken = store => {
    return store.session.refreshToken
}

export const getToken = store => {
    return store.session.token
}

export const getUserId = store => {
    const {userId} = store.session.token
    return userId
}

export const getUserRole = store => {
    return store.session.userRole
}