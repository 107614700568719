import { SubscriptionRepository } from "../../api/repo/SubscriptionRepository";
import { PrepaidCodeRepository } from "../../api/repo/PrepaidCodeRepository";

export class ViewSubscriptionPresenter {
    subscriptionRepo = new SubscriptionRepository;
    prepaidCodeRepo = new PrepaidCodeRepository;

    getOrganisationDetails(args) {
        this.subscriptionRepo.getOrganisationDetails(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

    applyPrepaidCode(args) {
        this.subscriptionRepo.applyPrepaidCode(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

    applyDiscountCode(args) {
        this.subscriptionRepo.applyDiscountCode(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

    approvePayment(args) {
        this.prepaidCodeRepo.approvePayment(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }
    
}