import {Label, Button, Divider, Grid, Accordion, Modal, Form, Input, Checkbox, Message} from "semantic-ui-react";
import { getDateFormat, formatExpiredDate} from "../../utilities/CommonUtils";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useEffect, useState, useRef } from "react";
import { PaymentStatus } from "../../constant/Constant";
import Style from '../../Style';
import { RevokeConfirmationPopup } from "./RevokeConfirmationPopup";
import { RevokeConfirmationPopupPresenter } from "./RevokeConfirmationPopupPresenter";

export function DisplayCodeDetails(props) {

    useEffect(() => {
        if (props.data) {
            getPaidStatus(props.data.PaymentToCollect, props.data.PaymentReceived);
            getVoucherStatus(props.data);
        }
    },[props.data])

    const [paymentStatus, setPaymentStatus] = useState("");
    const [voucherStatus, setVoucherStatus] = useState("");
    const [displayRevokeCodePopup, setDisplayRevokeCodePopup] = useState(false);
    const [voucherCode, setVoucherCode] = useState("");
    const [buttonLoad, setButtonLoad] = useState(false);
    const [selectedTenantDetail, setselectedTenantDetail] = useState([]);
    const [displayConfirmMessage, setDisplayConfirmMessage] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [displayDisabledCodePopup, setDisplayDisabledCodePopup] = useState(false);
    const [disabledCode, setDisabledCode] = useState(false);

    const presenter = useRef(new RevokeConfirmationPopupPresenter());

    let title = isVoucherUsed() ? "Revoke & Disabled": "Disabled"

    function getUserCount(product) {
        if (props.data.Products !== null){
            let productDetails = props.data.Products.filter((item) => item.Name === product)
            if (productDetails.length > 0) {
                return productDetails.map((item) => item.UserCount);
            } else {
                return "-";
            }
        }
    }

    const details = (data) => (
        <div className="redeemInfoSection">
            <p><b>Organization Name:</b> {data.Name}</p>
            <p><b>Redeemed Date:</b> {getDateFormat(data.RedeemedDate)}</p>
        </div>
    )

    function getVoucherStatus(data) {
        if (data.IsRedeemed) {
            if (data.MaxRedeemCount === data.CurrentRedeemCount) {
                setVoucherStatus("Fully Redeemed");
            } else {
                setVoucherStatus("Partial Redeemed");
            }
        } else {
            if (!data.IsActive) {
                setVoucherStatus("Disabled");
            } else {
                setVoucherStatus("Created");
            }
        }
    }

    function getPaidStatus(paymentToCollect, paymentReceived) {
        if (paymentToCollect && !paymentReceived) {
            setPaymentStatus(PaymentStatus.UNPAID);
        } else {
            setPaymentStatus(PaymentStatus.PAID);
        }
    }

    console.log("lla", props.data)
    console.log("kk",props.data.RedeemedTenantInfo)

    const revokeCodePopup = () => (
        <Modal
            size='tiny'
            open={displayRevokeCodePopup}
            onClose={() => setDisplayRevokeCodePopup(false)}
            onOpen={() => setDisplayRevokeCodePopup(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header style={Style.centraliseTitle}>
                Revoke Prepaid Code - {props.data.Code}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        Select an organisation to revoke the code from:
                        {props.data.RedeemedTenantInfo !== null && props.data.RedeemedTenantInfo.map(item => 
                            (
                                <Checkbox
                                    radio
                                    label={item.Name}
                                    name='checkboxRadioGroup'
                                    value={item.TenantID}
                                    checked={
                                        selectedTenantDetail.length > 0 ?
                                            selectedTenantDetail[0].TenantID === item.TenantID ? true : false:
                                            false
                                    }
                                    onChange={(e, data) => {
                                        let tenantDetails = props.data.RedeemedTenantInfo.filter((item) => item.TenantID === data.value);
                                        console.log("dets", tenantDetails);
                                        setselectedTenantDetail(tenantDetails);
                                    }}
                                />
                            )
                        )}
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {setDisplayRevokeCodePopup(false); setVoucherCode("")}}>
                    Cancel
                </Button>
                <Button
                    color="red"
                    content="Revoke"
                    loading={buttonLoad}
                    disabled={selectedTenantDetail.length !== 0 ? false : true}
                    onClick={() => {
                        if (checkVoucherHasExpired()) {
                            setErrorMsg("Voucher expired. Cannot be revoked.");
                        } else {
                            setDisplayConfirmMessage(true);
                        }
                    }}
                />
                {errorMsg && 
                    <Message negative style={{marginLeft:"1px"}}>
                        <Message.Header>{errorMsg}</Message.Header>
                    </Message>
                }
            </Modal.Actions>
        </Modal>
    )

    function isVoucherUsed() {
        if (voucherStatus !== "Created") {
            if(props.data.RedeemedTenantInfo !== null) {
                return true;
            } else {
                return false;
            }
        } else { 
            return false;
        }
    }

    function checkVoucherHasExpired() {
        let redeemDetails = props.data.RedeemedTenantInfo;
        for (let x = 0; x < redeemDetails.length; x++) {
            let redeemDate = new Date(redeemDetails[x].RedeemedDate);
            let voucherExpireDate = formatExpiredDate(new Date(redeemDate.setMonth(redeemDate.getMonth()+props.data.PrepaidDuration)-1));
            let todayDate = formatExpiredDate(new Date());

            let expiredDate = new Date(voucherExpireDate.toString());
            let today = new Date(todayDate.toString());

            if (expiredDate.getTime() > today.getTime()) {
                return false;
            } else {
                return true;
            }
        }
    }

    const confirmPopup = () => 
        <RevokeConfirmationPopup
            openModal={displayConfirmMessage}
            updateModal={setDisplayConfirmMessage}
            voucherCode={props.data.Code}
            organisationName={selectedTenantDetail[0].Name}
            buttonLoad={buttonLoad}
            callRevoke={()=>revokeCode()}
            disabled={disabledCode}
            tenantID={selectedTenantDetail[0].TenantID}
            removeSelectedTenant= {()=>setselectedTenantDetail([])}
        />

    function revokeCode() {
        setDisplayRevokeCodePopup(false);
        props.refreshPage();
    }

    function disabledCodeApi() {
        let tenantIdsArray = selectedTenantDetail.length> 0 ? [selectedTenantDetail[0].TenantID] : [];

        if (tenantIdsArray.length === 0) {
            if(props.data.RedeemedTenantInfo !== null && props.data.RedeemedTenantInfo.length > 0) {
                for(let i = 0; i<props.data.RedeemedTenantInfo.length; i++){
                    tenantIdsArray.push(props.data.RedeemedTenantInfo[i].TenantID)
                }
            }
        }

        let request= {
            Code :   props.data.Code,
            TenantIDs : tenantIdsArray, 
            Disable : true
        }

        presenter.current.revokePrepaidCode({
            request,
            onSuccess: (resposne) => {                
                props.refreshPage();
            },
            onError: (error) => {
                console.log("error", error)
            }
        })
    }

    const disabledCodePopup = () => {
        return <Modal
            size='tiny'
            open={displayDisabledCodePopup}
            onClose={() => setDisplayDisabledCodePopup(false)}
            onOpen={() => setDisplayDisabledCodePopup(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header style={Style.centraliseTitle}>
                {title} Prepaid Code
            </Modal.Header>
            <Modal.Content>
                Are you sure you want to {title.toLowerCase()} this prepaid code:
                <p>{props.data.Code} ?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setDisplayDisabledCodePopup(false)}>
                    Cancel
                </Button>
                <Button
                    color="red"
                    content="Confirm"
                    loading={props.buttonLoad}
                    onClick={() => {
                        disabledCodeApi();
                        setDisplayDisabledCodePopup(false);
                    }}
                />
            </Modal.Actions>
        </Modal>
    }
        

    return <Grid columns='equal'>
        <Grid.Row>
            <Grid.Column columns={2}>
                <Divider horizontal>Code and Description</Divider>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Code : </b>
            </Grid.Column>
            <Grid.Column>{props.data.Code}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Description : </b>
            </Grid.Column>
            <Grid.Column>{props.data.Description === "" ? "-" : props.data.Description}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Status :</b>
            </Grid.Column>
            <Grid.Column>{voucherStatus}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Customer Email :</b>
            </Grid.Column>
            <Grid.Column>{props.data.CreatedForEmail === "" ? "-" :props.data.CreatedForEmail}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Created On : </b>
            </Grid.Column>
            <Grid.Column>{getDateFormat(props.data.CreatedOn)}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Created By :</b>
            </Grid.Column>
            <Grid.Column>{props.data.CreatedBy}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column columns={2}>
                <Divider horizontal>Plan and Duration</Divider>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Redeemed By :</b>
            </Grid.Column>
            <Grid.Column>
                {(props.data.IsRedeemed && props.data.RedeemedTenantInfo !== null) ? 
                    <Accordion className="accordionSection" defaultActiveIndex={0} exclusive={props.data.MaxRedeemCount === props.data.RedeemedTenantInfo.length ? true : false} panels={props.data.RedeemedTenantInfo.map((item) => {
                        return {
                            key: item._id,
                            title: item.OwnerEmail,
                            content: {content:details(item)},
                        }
                    }
                )} />
                : '-'}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Plan : </b>
            </Grid.Column>
            <Grid.Column>{props.data.Plan}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Prepaid Duration (months) :</b>
            </Grid.Column>
            <Grid.Column>{props.data.PrepaidDuration}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Applicable Times : </b>
            </Grid.Column>
            <Grid.Column>{props.data.MaxRedeemCount === props.data.CurrentRedeemCount ? "0" : (props.data.MaxRedeemCount - props.data.CurrentRedeemCount )}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column columns={2}>
                <Divider horizontal>Payment Details</Divider>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Payment Status :</b>
            </Grid.Column>
            <Grid.Column>{paymentStatus}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Amount :</b>
            </Grid.Column>
            <Grid.Column>{props.data.Currency.toUpperCase()} {props.data.Amount}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5}>
                <b>Payment Link :</b>
            </Grid.Column>
            <Grid.Column>
                <span>{props.data.PaymentLink ? props.data.PaymentLink : '-'}</span>
                &nbsp;
                {(props.data.PaymentToCollect && !props.data.PaymentReceived) && 
                    <CopyToClipboard text={props.data.PaymentLink }>
                        <Label as="a">Copy link</Label>
                    </CopyToClipboard>
                }
            </Grid.Column>
        </Grid.Row>
        {props.data.PaymentTransactionReference.length>0 &&
            <Grid.Row>
                <Grid.Column width={5}>
                    <b>Payment Reference :</b>
                </Grid.Column>
                <Grid.Column>{props.data.PaymentTransactionReference}</Grid.Column>
            </Grid.Row>
        }
        <Grid.Row>
            <Grid.Column columns={2}>
                <Divider horizontal>Products and Users</Divider>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingTop:'-20px'}}>
            <Grid.Column width={5}>
                <b>Product</b>
            </Grid.Column>
            <Grid.Column>
                <b>No. of Paid User</b>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingTop:'-20px'}}>
            <Grid.Column width={5}>
                Books
            </Grid.Column>
            <Grid.Column>{getUserCount("ERP")}</Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingTop:'-20px'}}>
            <Grid.Column width={5}>
                Sales
            </Grid.Column>
            <Grid.Column>
                    {getUserCount("CRM")}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingTop:'-20px'}}>
            <Grid.Column width={5}>
                People
            </Grid.Column>
            <Grid.Column>
                {getUserCount("PEOPLE")}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingTop:'-20px'}}>
            <Grid.Column width={5}>
                MRP
            </Grid.Column>
            <Grid.Column>{getUserCount("MRP")}</Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingBottom:0}}>
            <Grid.Column>
                <Divider/>
            </Grid.Column>
        </Grid.Row>
        {(props.searchPage && (!props.data.PaymentReceived && props.data.PaymentToCollect)) && 
            <Grid.Row style={{paddingTop:0}}>
                <Grid.Column>
                    <Button color="blue" style={{width:'100%', marginLeft: 0}} onClick={()=> props.onClick && props?.onClick() }>Approve Payment</Button>
                </Grid.Column>
            </Grid.Row>
        }
        {(props.searchPage && paymentStatus === PaymentStatus.PAID && props.data.CreatedForEmail !== "" && props.data.MaxRedeemCount !== props.data.CurrentRedeemCount) && 
            <Grid.Row style={{paddingTop:0}}>
                <Grid.Column>
                    <Button color="blue" style={{width:'100%', marginLeft: 0}} onClick={()=> props.applyPrepaidCode && props?.applyPrepaidCode() }>Apply Prepaid Code</Button>
                </Grid.Column>
            </Grid.Row>
        }
        {isVoucherUsed() &&
            <Grid.Row style={{paddingTop:0}}>
                <Grid.Column>
                    <Button color="red" style={{width:'100%', marginLeft: 0}} onClick={()=>setDisplayRevokeCodePopup(true)}>Revoke Prepaid Code</Button>
                </Grid.Column>
            </Grid.Row>
        }
        {voucherStatus !== "Disabled" &&
            <Grid.Row style={{paddingTop:0}}>
                <Grid.Column>
                    <Button color="red" style={{width:'100%', marginLeft: 0}} onClick={()=>setDisplayDisabledCodePopup(true)}>{title} Prepaid Code</Button>
                </Grid.Column>
            </Grid.Row>
        }
        {displayRevokeCodePopup && revokeCodePopup()}
        {displayConfirmMessage && confirmPopup()}
        {displayDisabledCodePopup && disabledCodePopup()}
    </Grid>
}