import React, {useEffect, useRef, useState} from 'react';
import {Route, Switch} from 'react-router-dom';

// context
import DeskLoader from '../deskLoader/DeskLoader';
import {decodeToken} from '../../api/utilities/TokenUtils';
import {AuthToken} from '../../api/client/BaseApiClient';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    saveRefreshToken,
    saveToken,
    saveUser,
    saveUserRole
} from '../../redux/session/actions';
import {LayoutPresenter} from './presenter/LayoutPresenter';
import {
    getRefreshToken,
    getToken,
    getUserName,
} from '../../redux/session/selector';
import CreatePrepaidVoucher from '../create_prepaid_voucher/CreatePrepaidVoucher';
import ViewVoucher from '../view_code/ViewVoucher';
import SearchPrepaidCode from '../search_prepaid_code/SearchPrepaidCode';
import CreateDiscountCode from '../create_discount_code/CreateDiscountCode';
import SearchSubscriptionPage from '../search_subscription/SearchSubscriptionPage';
import SearchUserPage from '../search_user/SearchUserPage';
import DisableMFAForUser from '../disableMFA/DisableMFAForUser'
import {handleError} from "../../utilities/AlertableErrorHandler";
import {useSnackbar} from "notistack";
import {  Modal } from 'semantic-ui-react'
import { VerifiedUser } from '@material-ui/icons';
import VerifyUserPage from '../verify_email/VerifyUserPage';
import SetUserPasswordPage from '../set_password/SetUserPasswordPage';

function Layout(props) {
    const presenter = useRef(new LayoutPresenter());

    const [visibleProgress, setVisibleProgress] = useState(true);
    const [userAccess, setUserAccess] = useState(true);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        checkIfUserLoggedIn();
    }, []);

    return (
        <div>
            {!visibleProgress &&
            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <div>
                    <div>
                        <Switch>
                            <Route path="/view_prepaid_code" component={ViewVoucher}/>   
                            <Route path="/create_prepaid_code" component={CreatePrepaidVoucher}/>
                            <Route path="/search_prepaid_code" component={SearchPrepaidCode}/>
                            <Route path="/view_discount_code" component={CreateDiscountCode}/>   
                            <Route path="/create_discount_code" component={CreateDiscountCode}/>
                            <Route path="/search_discount_code" component={CreateDiscountCode}/>  
                            <Route path="/subscription" component={SearchSubscriptionPage}/>
                            <Route path="/user" component={SearchUserPage}/>
                            <Route path="/verifyemail" component={VerifyUserPage}/>
                            <Route path="/setpassword" component={SetUserPasswordPage}/>
                            <Route path="/disablemfa" component={DisableMFAForUser}/>
                        </Switch>
                    </div>
                </div>
            </div>}
            {visibleProgress && <DeskLoader
                heightBox="100vh"
                widthBox="100wh"
                heightLogo="400"
                widthLogo="400"
            />}
            {!userAccess &&  <Modal
                style={{width:'max-content'}}
                dimmer='blurring'
                open={!userAccess}
            >
                <Modal.Content>
                    You have no access to this site. Please contact your admin.
                </Modal.Content>
            </Modal>}
        </div>
    );

    function checkIfUserLoggedIn() {
        setVisibleProgress(true);
        presenter.current.checkLoginStatus({
            onSuccess: (data) => {
                const {idToken, refreshToken} = data;
                const decodedToken = decodeToken(idToken);
                props.saveToken(decodedToken);
                props.saveRefreshToken(refreshToken);
                AuthToken.token = idToken;
                const {userId} = decodedToken;
                getUserDetails(userId, decodedToken);
            },
            onError: (error) => {
                setVisibleProgress(false);
                showError(error);
            },
        });
    }

    function showError(error) {
        handleError(error, enqueueSnackbar)
    }

    function getUserDetails(userId, decodedToken) {
        presenter.current.getUserDetails({
            userId,
            onSuccess: (data) => {
                props.saveUser(data.content[0]);
                getAdminPermission();
                // setVisibleProgress(false);
            },
            onError: (error) => {
                setVisibleProgress(false);
                showError(error);
            },
        });
    }

    function getAdminPermission() {
        presenter.current.checkAdminStatus({
            onSuccess: (data) => {
                if (data.Access === "Admin") {
                    setUserAccess(true);
                } else {
                    setUserAccess(false);
                }
                props.saveUserRole(data.Access);
                setVisibleProgress(false);
            },
            onError: (error) => {
                if (error === "User not authorized") {
                    props.saveUserRole("not authorized");
                    setVisibleProgress(false);
                    setUserAccess(false);
                } else {
                    setVisibleProgress(false);
                    showError(error);
                }
            },
        });
    }

}

function mapStateToProps(state = {
    user: {},
    refreshToken: {},
    token: {},
}) {
    let refreshToken = getRefreshToken(state);
    let token = getToken(state);
    const userName = getUserName(state);
    return {
        userName: userName,
        refreshToken: refreshToken,
        token: token,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveUser,
        saveToken,
        saveRefreshToken,
        saveUserRole
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
