import { useState, useRef, useEffect } from "react";
import { FilterValues, FilterOptions, PaymentOptions, PaymentGatewayOptions } from "../../../constant/Constant";
import { getVoucherType } from "../../../utilities/CommonUtils";

import Style from '../../../Style';

import { CreatePrepaidVoucherPresenter } from "./CreatePrepaidVoucherPresenter";

import {Button, Sidebar, Modal, Select, Form, Input, Table, Checkbox, Grid, Message, Divider, Label, Popup} from "semantic-ui-react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export function CreatePrepaidVoucherCode(props) {

    const [open, setOpen] = useState(false);
    const [prepaidDurationError, setPrepaidDurationError] = useState(false);
    const [redeemCountError, setRedeemCountError] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [erpCheckbox, setERPCheckbox] = useState(true);
    const [crmCheckbox, setCRMCheckbox] = useState(false);
    const [peopleCheckbox, setPeopleCheckbox] = useState(false);
    const [mrpCheckbox, setMrpCheckbox] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [startDate, setStartDate] = useState(getValidityDate());
    const [payment, setPayment] = useState(false);
    const [voucherDetails, setVoucherDetails] = useState({
        voucherCode: '',
        desc: '',
        plan: FilterValues.STARTUP,
        prepaidDuration: '1',
        maxRedeemCount: '1',
        erpUser: '1',
        crmUser: '1',
        pplUser: '1',
        customerReference: '',
        salespersonReference: '',
        paymentToCollect: payment,
        voucherAmount: '0',
        paymentReference: '',
        paymentCurrency: 'inr',
        paymentGateway: '',
        CreatedForEmail: '',
        CreatedForUserID: 0,
        CreatedForTenantID:0
    });
    const [createdVoucherCode, setCreatedVoucherCode] = useState("");
    const [buttonLoad, setButtonLoad] = useState(false);
    const [paymentLink, setPaymentLink] = useState("");
    const [overrideCheckbox, setOverrideCheckbox] = useState(false);
    const [paymentButtonLoad, setPaymentButtonLoad] = useState(false);
    const [paid, setAsPaid] = useState(false);

    const presenter = useRef(new CreatePrepaidVoucherPresenter());

    useEffect(() => {
        if (!payment){
            setAmountError(false);
            setVoucherDetails({...voucherDetails, paymentReference:"", paymentCurrency:"inr", voucherAmount:"", paymentGateway:"", paymentReference:""})
        } else {
            setAmountError(false);
                setVoucherDetails({...voucherDetails, paymentGateway:"", paymentCurrency:"inr", voucherAmount:"", paymentGateway: "razorpay", paymentReference:""})
        }
    }, [payment]);

    function getValidityDate() {
        return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    }

    function generateRandomCode(e) {
        let randValue = "";
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                randValue += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            setVoucherDetails({...voucherDetails, voucherCode: randValue})
            e.preventDefault()
    }

    function numberValidation(value, field) {
        var numbers = /^[-+]?[0-9]+$/;
        setVoucherDetails({...voucherDetails, [field]: value})
        if (value.match(numbers)){
            if (field === "prepaidDuration") {
                setPrepaidDurationError(false);
            } else if (field === "maxRedeemCount") {
                setRedeemCountError(false);
            }
        } else {
            if (field === "prepaidDuration") {
                setPrepaidDurationError(true);
            } else if (field === "maxRedeemCount") {
                setRedeemCountError(true);
            }
        }
    }

    function amtValidation(value, field) {
        var amtPattern = /^\d*(?:[.,]\d*)?$/;
        setVoucherDetails({...voucherDetails, [field]: value});
        if (value.match(amtPattern)) {
            setAmountError(false);
        } else {
            setAmountError(true);
        }
    }

    function handleSubmit() {
        if (
            voucherDetails.voucherCode === ""
        ) {
            setErrorMsg("Enter Code");
            return
        }

        if ( !(erpCheckbox || crmCheckbox || peopleCheckbox)) {
            setErrorMsg("Please check at least one of the Products.");
            return
        }

        let productArray = [];

        if (erpCheckbox) {
            productArray = [...productArray, {
                Name: "ERP",
                UserCount: parseInt(voucherDetails.erpUser)
            }]
        }

        if (mrpCheckbox) {
            productArray = [...productArray, {
                Name: "MRP",
                UserCount: parseInt(voucherDetails.erpUser)
            }]
        }


        if (crmCheckbox) {
            productArray = [...productArray, {
                Name: "CRM",
                UserCount: parseInt(voucherDetails.crmUser)
            }]
        }

        if (peopleCheckbox) {
            productArray = [...productArray, {
                Name: "PEOPLE",
                UserCount: parseInt(voucherDetails.pplUser)
            }]
        }

        setCreatedVoucherCode(voucherDetails.voucherCode);
        setButtonLoad(true);

        let request = {
            Code: voucherDetails.voucherCode,
            Plan: voucherDetails.plan,
            PrepaidDuration: parseInt(voucherDetails.prepaidDuration),
            ValidTill: startDate.toISOString().slice(0, 10),
            Amount: ( voucherDetails.voucherAmount === "" ? 0 : parseFloat(voucherDetails.voucherAmount)),
            Description: voucherDetails.desc,
            MaxRedeemCount: parseInt(voucherDetails.maxRedeemCount),
            Products: productArray,
            CustomerReference: ( voucherDetails.customerReference === "" ? " " : voucherDetails.customerReference),
            SalesPersonReference: ( voucherDetails.salespersonReference === "" ? " " : voucherDetails.salespersonReference),
            PaymentGateway: voucherDetails.paymentGateway,
            Currency: voucherDetails.paymentCurrency,
            PaymentToCollect: payment,
            PaymentCollectedReference: ( voucherDetails.paymentReference === "" ? " " :voucherDetails.paymentReference),
            ValidTill: startDate.toISOString().slice(0, 10),
            CreatedForEmail: (props.email !== undefined ? props.email : ""),
            CreatedForUserID: (props.orgDetails !== undefined ? props.orgDetails.UserID : 0),
            CreatedForTenantID: (props.orgDetails !== undefined ? props.orgDetails.TenantID : 0)
        };

        presenter.current.createPrepaidVoucher({
            request,
            onSuccess: (response) => {
                setPaymentLink(response.PaymentLink);
                setAsPaid(response.PaymentToCollect);
                setButtonLoad(false);
                setVoucherDetails({
                    voucherCode: '',
                    desc: '',
                    plan: FilterValues.STARTUP,
                    prepaidDuration: '1',
                    maxRedeemCount: '1',
                    erpUser: '1',
                    crmUser: '1',
                    pplUser: '1',
                    customerReference: '',
                    salespersonReference: '',
                    paymentToCollect: payment,
                    voucherAmount: '',
                    paymentReference: '',
                    paymentCurrency: 'inr',
                    paymentGateway: 'razorpay'
                })
                setStartDate(getValidityDate());
                setERPCheckbox(true);
                setCRMCheckbox(false);
                setPeopleCheckbox(false);
                setMrpCheckbox(false);
                setOpen(true);
                setErrorMsg(null);
                if (props?.subscriptionPage) {
                    props?.newVoucherCode(voucherDetails.voucherCode);
                }
            },
            onError: (error) => {
                setButtonLoad(false);
                setErrorMsg(error);
            }
        })
    }

    const paymentPopup = () => (
        <Modal
            style={{width: '25%'}}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            closeOnDimmerClick={false}
        > 
            <Modal.Header>
                <h3 style={Style.centraliseTitle}>Prepaid Code {createdVoucherCode} Created!</h3>
            </Modal.Header>
            { payment && 
                <Modal.Content>
                    <div>
                        Payment Link : <a>{paymentLink}</a>
                        &nbsp;
                        <CopyToClipboard text={paymentLink}>
                            <Label as="a">Copy link</Label>
                        </CopyToClipboard>
                    </div>
                    {props.subscriptionPage &&
                        <div>
                            <Divider/>
                            <p>
                                <Checkbox
                                    checked={overrideCheckbox}
                                    onChange={()=>setOverrideCheckbox(!overrideCheckbox)}
                                    label={<label>
                                        Approve Payment
                                        &nbsp;
                                        <Popup content='Approve without invoice payment validation.' trigger={<Label circular>?</Label>} />                                                      
                                    </label>}
                                />
                            </p>
                            <p style={{color:'red'}}>Before applying this prepaid code, please approve the payment first.</p>
                        </div>
                    }
                </Modal.Content>
            }
            <Modal.Actions>
                <Button style={{width: (props.subscriptionPage && !paid) ? '100%' : '48%', margin:0}} onClick={() => props.subscriptionPage ? props.onClick && props.onClick() : setOpen(false)}>Close</Button>
                {(props.subscriptionPage && paid) &&
                    <Button color='blue' style={{width:'48%'}} onClick={() => approvePayment(createdVoucherCode)} loading={paymentButtonLoad}>Approve</Button>
                }
                {errorMsg && 
                    <Message negative>
                        <Message.Header>{errorMsg}</Message.Header>
                        {errorMsg === "Error getting coupon" && 
                            <p>{errorMsg}</p>
                        }
                    </Message>
                }
            </Modal.Actions>
        </Modal>
    )

    function approvePayment(code) {
        setPaymentButtonLoad(true);
        let request = {
            Code: code,
            ForceApprove :overrideCheckbox
        }

        presenter.current.approvePayment({
            request,
            onSuccess: (response) => {
                setPaymentButtonLoad(false);
                setErrorMsg(null);
                setOpen(false);
                props.onClick();
            },
            onError: (error) => {
                setPaymentButtonLoad(false);
                setErrorMsg(error);
                setOpen(true);
            }
        })
    }

    function clearForm() {
        setVoucherDetails({
            voucherCode: '',
            desc: '',
            plan: FilterValues.STARTUP,
            prepaidDuration: '1',
            maxRedeemCount: '1',
            erpUser: '1',
            crmUser: '1',
            pplUser: '1',
            customerReference: '',
            salespersonReference: '',
            paymentToCollect: payment,
            voucherAmount: '',
            paymentReference: '',
            paymentCurrency: 'inr',
            paymentGateway: 'razorpay'
        })
        setStartDate(getValidityDate());
        setERPCheckbox(true);
        setCRMCheckbox(false);
        setPeopleCheckbox(false);
        setMrpCheckbox(false);
        setErrorMsg(null);
    }

    return <div>
        <Form>
            <h3 style={Style.sectionHeader}>Code and Description</h3>
            <Form.Group widths='equal'>
                <Form.Field
                    control={Input}
                    label={
                        <label>
                            Code 
                            <small>(Max. 36 characters)</small>
                            <label className='requiredStyle'>*</label>
                        </label>
                    }
                    maxLength="36"
                    placeholder='Voucher Code'
                    value={voucherDetails.voucherCode}
                    onChange={(e, data) => setVoucherDetails({...voucherDetails, voucherCode: data.value})}
                    action={<Button onClick={(e,data)=>generateRandomCode(e)}>Random</Button>}
                />
                <Form.Field
                    control={Input}
                    label={<label>Description <small>(Max. 150 characters)</small></label>}
                    placeholder='Describe what is the voucher for...'
                    value={voucherDetails.desc}
                    onChange={(e, data) => setVoucherDetails({...voucherDetails, desc: data.value})}
                    maxLength={150}
                />
            </Form.Group>
            <Divider section/>
            <h3 style={Style.sectionHeader}>Plan and Duration</h3>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field
                            control={Select}
                            label='Plan'
                            options={FilterOptions}
                            placeholder='Plan'
                            value={voucherDetails.plan}
                            onChange={(e, data) => setVoucherDetails({...voucherDetails, plan: data.value})}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field
                            control={Input}
                            label='Prepaid Duration (months)'
                            placeholder='Ex. 12'
                            value={voucherDetails.prepaidDuration}
                            onChange={(e, data) => numberValidation(data.value, "prepaidDuration")}
                            error={(voucherDetails.prepaidDuration < 1 || prepaidDurationError)&& {
                                content: 'Enter a valid number, must be more than 1'
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field
                            control={Input}
                            label={<label>
                                Redeem Count  
                                &nbsp;
                                <Popup content='How many users can redeem this code?' trigger={<Label circular>?</Label>} />                                                      
                            </label>}
                            placeholder='Ex. 1'
                            value={voucherDetails.maxRedeemCount}
                            onChange={(e, data) => numberValidation(data.value, "maxRedeemCount")}
                            error={(voucherDetails.maxRedeemCount < 1 || redeemCountError) && {
                                content: 'Enter a valid number, must be more than 1'
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <label>
                                Validity 
                                &nbsp;
                                <Popup content='Until when can this code be applied?' trigger={<Label circular>?</Label>} />                                                      
                            </label>
                            <DatePicker 
                                minDate={Date.now() + ( 3600 * 1000 * 24)}
                                selected={startDate} 
                                onChange={(date) => setStartDate(date)} 
                                dateFormat={"yyyy-MM-dd"}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider section/>
            <h3 style={Style.sectionHeader}>Products and Users</h3>
            <Table basic='very' collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Product</Table.HeaderCell>
                        <Table.HeaderCell>No. of Paid User</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Checkbox
                                checked={erpCheckbox}
                                onChange={()=>setERPCheckbox(!erpCheckbox)}
                            />
                        </Table.Cell>
                        <Table.Cell>Books</Table.Cell>
                        {erpCheckbox ?
                            <Table.Cell>
                                <Form.Field
                                    inline
                                    control={Input}
                                    placeholder='Ex. 1'
                                    type="number"
                                    value={voucherDetails.erpUser}
                                    onChange={(e, data) => setVoucherDetails({...voucherDetails, erpUser: data.value})}
                                    error={voucherDetails.erpUser < 1 && {
                                        content: 'Enter a valid number, must be more than 1',
                                        pointing: 'left'
                                    }}
                                />
                            </Table.Cell> :
                            <Table.Cell>
                                <Form.Field>0</Form.Field>
                            </Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Checkbox
                                checked={crmCheckbox}
                                onChange={()=>setCRMCheckbox(!crmCheckbox)}
                            />
                        </Table.Cell>
                        <Table.Cell>Sales</Table.Cell>
                        {crmCheckbox ?
                            <Table.Cell>
                                <Form.Field
                                    inline
                                    control={Input}
                                    placeholder='Ex. 1'
                                    type="number"
                                    value={voucherDetails.crmUser}
                                    onChange={(e, data) => setVoucherDetails({...voucherDetails, crmUser: data.value})}
                                    error={voucherDetails.crmUser < 1 && {
                                        content: 'Enter a valid number, must be more than 1',
                                        pointing: 'left'
                                    }}
                                />
                            </Table.Cell> :
                            <Table.Cell>
                                <Form.Field>0</Form.Field>
                            </Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Checkbox
                                checked={peopleCheckbox}
                                onChange={()=>setPeopleCheckbox(!peopleCheckbox)}
                            />
                        </Table.Cell>
                        <Table.Cell>People</Table.Cell>
                        {peopleCheckbox ?
                            <Table.Cell>
                                <Form.Field
                                    inline
                                    control={Input}
                                    placeholder='Ex. 1'
                                    type="number"
                                    value={voucherDetails.pplUser}
                                    onChange={(e, data) => setVoucherDetails({...voucherDetails, pplUser: data.value})}
                                    error={voucherDetails.pplUser < 1 && {
                                        content: 'Enter a valid number, must be more than 1',
                                        pointing: 'left'
                                    }}
                                />
                            </Table.Cell> :
                            <Table.Cell>
                                <Form.Field>0</Form.Field>
                            </Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        { erpCheckbox &&
                            <div>
                            <Table.Cell>
                            <Checkbox
                                checked={mrpCheckbox}
                                onChange={()=>setMrpCheckbox(!mrpCheckbox)}
                            />
                            </Table.Cell>
                            <Table.Cell>
                                MRP
                            </Table.Cell>
                            </div>
                        }
                    </Table.Row>
                </Table.Body>
            </Table>
            <Divider section/>
            <h3 style={Style.sectionHeader}>Payment Details</h3>
            <Grid columns={3}>
                <Grid.Row>
                    <Grid.Column>
                        <Checkbox
                            label='Mark As Paid'
                            name='checkboxRadioGroup'
                            checked={!payment}
                            onChange={(e, data) => setPayment(!payment)}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field
                            control={Select}
                            label={<label style={payment ? {paddingBottom:'0.3em'} : {paddingBottom:'0'}}>Currency</label>}
                            placeholder='Select Currency'
                            options={PaymentOptions}
                            value={voucherDetails.paymentCurrency}
                            onChange={(e, data) => setVoucherDetails({...voucherDetails, paymentCurrency: data.value})}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field
                            control={Input}
                            label={
                                <label>
                                    Amount
                                    {payment &&
                                        <span>
                                            <label className='requiredStyle'>*</label>
                                            &nbsp;
                                        <Popup content='Amount value must not be less than 0.50.' trigger={<Label circular>?</Label>} />
                                        </span>
                                    }
                                </label>
                            }
                            placeholder='0.00'
                            value={voucherDetails.voucherAmount}
                            onChange={(e, data) => amtValidation(data.value, "voucherAmount")}
                            error={!payment ? amountError && {
                                content: 'Please enter a valid amount value.'
                            } : (amountError || parseFloat(voucherDetails.voucherAmount) < 0.5) && {
                                content: 'Please enter a valid amount value and no less than 0.50.'
                            }}
                        />
                    </Grid.Column>
                    {!payment && <Grid.Column>
                        <Form.Field
                            control={Input}
                            label="Payment Reference"
                            value={voucherDetails.paymentReference}
                            onChange={(e, data) => setVoucherDetails({...voucherDetails, paymentReference: data.value})}
                        />
                    </Grid.Column>}
                    {payment && <Grid.Column>
                        <Form.Select
                            label={<label style={payment ? {paddingBottom:'0.3em'} : {paddingBottom:'0'}}>Payment Gateway<label className='requiredStyle'>*</label></label>}
                            options={PaymentGatewayOptions}
                            value={voucherDetails.paymentGateway}
                            onChange={(e, data) => setVoucherDetails({...voucherDetails, paymentGateway: data.value})}
                        />
                    </Grid.Column>}
                </Grid.Row>
            </Grid>
            <Divider section/>
           <h3 style={Style.sectionHeader}>Additional Details</h3>
           <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>
                                Customer Reference
                                &nbsp;
                                <Popup content='Eg. Customer Email, Organisation, etc...' trigger={<Label circular>?</Label>} />
                            </label>
                            <Input
                                value={voucherDetails.customerReference}
                                onChange={(e, data) => setVoucherDetails({...voucherDetails, customerReference: data.value})}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <label>
                                Salesperson Reference
                                &nbsp;
                                <Popup content='Eg. Salesperson Email, Name, etc...' trigger={<Label circular>?</Label>} />
                            </label>
                            <Input
                                value={voucherDetails.salespersonReference}
                                onChange={(e, data) => setVoucherDetails({...voucherDetails, salespersonReference: data.value})}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider section/>
            <Button.Group className='formGroup'>
                <Button onClick={() => clearForm()}>Clear</Button>
                <Button.Or />
                <Button color='blue' loading={buttonLoad} onClick={() => handleSubmit()}>Submit</Button>
            </Button.Group>
        </Form>
        {open && paymentPopup()}
        {errorMsg && <Message negative>
            <Message.Header>{errorMsg}</Message.Header>
        </Message>}
    </div>
}