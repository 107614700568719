import React, {useEffect, useState} from "react";
import logo from "./../../assets/deskera-logo.gif"

function DeskLoader(props) {
    const [height, setHeight] = useState(undefined);
    const [width, setWidth] = useState(undefined);
    const [heightLogo, setHeightLogo] = useState(undefined);
    const [widthLogo, setWidthLogo] = useState(undefined);

    useEffect(() => {
        setHeight(props.heightBox);
        setWidth(props.widthBox);
        setHeightLogo(props.heightLogo);
        setWidthLogo(props.widthLogo);
    }, [props]);

    return <div style={{
        height: height,
        width: width,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <img
            width={widthLogo}
            height={heightLogo}
            src={logo}
            alt="Loading..."/>
    </div>
}

export default DeskLoader;
