import React, {useEffect, useRef, useState} from 'react';
import Style from '../../Style';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SidebarLink from "../sidebar/SidebarLink";
import {handleError} from "../../utilities/AlertableErrorHandler";
import {useSnackbar} from "notistack";
import { useHistory } from "react-router-dom";
import { getVoucherType } from '../../utilities/CommonUtils';
import { getUserRole } from '../../redux/session/selector';

import "./style.css";

import {Button, Dimmer, Sidebar, Loader, Pagination, Segment, Table, Dropdown, Modal, Icon, Divider, Checkbox, Popup, Label, Message} from "semantic-ui-react";

import _ from 'lodash';

import { FilterValues, FilterOptions, SortOptions, TableColumn, PageSize, PaymentStatus } from '../../constant/Constant';
import { ViewVoucherPresenter } from './ViewVoucherPresenter';
import { getDateFormat } from '../../utilities/CommonUtils';

import MainHeader from '../mainHeader/MainHeader';
import { DisplayCodeDetails } from '../common/DisplayCodeDetails';
import { ApprovePaymentForm } from '../common/ApprovePaymentForm';
import { ApproveMessage } from '../common/ApproveMessage';
import { ApplyPrepaidCodeModal } from '../common/ApplyPrepaidCodeModal';

function exampleReducer(state, action) {
    switch (action.type) {
        case 'CHANGE_SORT':
            if (state.column === action.column) {
                let filterResult;
                if (action.column === TableColumn.Amount) {
                    filterResult = state.data.sort((a, b) => a.Amount - b.Amount);
                } else if (action.column === TableColumn.Code) {
                    filterResult = state.data.sort((a, b) => a.Code.localeCompare(b.Code, 'en', {ignorePunctuation: true}));
                } else if (action.column === TableColumn.Plan) {
                    filterResult = state.data.sort((a, b) => a.Plan.localeCompare(b.Plan, 'en', {ignorePunctuation: true}));
                } else if (action.column === TableColumn.Duration) {
                    filterResult = state.data.sort((a, b) => a.PrepaidDuration - b.PrepaidDuration);
                } else if (action.column === TableColumn.CreatedOn) {
                    filterResult = state.data.sort((a, b) => a.CreatedOn.localeCompare(b.CreatedOn, 'en', {ignorePunctuation: true}));
                } else if (action.column === TableColumn.CreatedBy) {
                    filterResult = state.data.sort((a, b) => a.CreatedBy.localeCompare(b.CreatedBy, 'en', {ignorePunctuation: true}));
                } else if (action.column === TableColumn.CreatedFor) {
                    filterResult = state.data.sort((a, b) => a.CreatedForEmail.localeCompare(b.CreatedForEmail, 'en', {ignorePunctuation: true}));
                }
                return {
                    ...state,
                    data: state.direction === 'ascending' ? filterResult : filterResult.reverse(),
                    direction:
                    state.direction === 'ascending' ? 'descending' : 'ascending',
                }
            }
    
            return {
                column: action.column,
                data: _.sortBy(state.data, [action.column]),
                direction: 'ascending',
            }

        case 'FILTER':
            if (action.selectedFilter === FilterValues.STARTUP || action.selectedFilter === FilterValues.ESSENTIAL || action.selectedFilter === FilterValues.PROFESSIONAL) {
                return {
                    data: action.tableData.filter((item) => item.Plan === action.selectedFilter),
                }
            } else {
                return {
                    data: action.tableData
                }
            }
        case 'UPDATE':
            return { data: action.tableData }
      default:
        throw new Error()
    }
}

function ViewVoucher(props) {

    const [dimmerLoader, setDimmerLoader] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [tableDetails, setTableDetails] = useState([]);
    const [sortBy, setSortBy] = useState("createdon");
    const [filterBy, setFilterBy] = useState("");
    const [pageSize, setPageSize] = useState(15);
    const isFirstRender = useRef(true);

    const history = useHistory();
    const presenter = useRef(new ViewVoucherPresenter());
    const {enqueueSnackbar} = useSnackbar();

    const [state, dispatch] = React.useReducer(exampleReducer, {
        column: null,
        data: tableDetails,
        direction: null,
        selectedFilter: null
      })

    const { column, data, direction } = state;
    const [open, setOpen] = useState(false);
    const [openApprovedModal, setOpenApprovedModal] = useState(false);
    const [codeDetails, setCodeDetails] = useState();
    const [openApprovedPaymentSuccessfulModal, setOpenApprovedPaymentSuccessfulModal] = useState(false);
    const [approvedMessage, setApprovedMessage] = useState("");
    const [openApplyCodeModal, setOpenApplyCodeModal] = useState(false);
    const [override, setOverride] = useState(false);
    const [tenantName, setTenantName] = useState();
    const [buttonLoad, setButtonLoad] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (props.userRole !== "not authorized"){
            setDimmerLoader(true);
            getTableData();
        }
    }, []);

    useEffect(() => {
        if (!isFirstRender.current){
            updateTable()
        }
    }, [pageNumber]);

    useEffect(() => {
        if (!isFirstRender.current){
            updateTable()
        }
    }, [sortBy]);

    useEffect(() => {
        if (!isFirstRender.current){
            setPageNumber(1);
            updateTable();
        }
    },[pageSize])

    function updateTable() {
        setDimmerLoader(true);
        getTableData();
    }

    function getTableData() {
        setFilterBy("");

        let pageNo = pageNumber-1;

        presenter.current.getListOfPrepaidVoucher({
            page: pageNo,
            sortBy: sortBy,
            pageSize: pageSize,
            onSuccess: (response) => {
                console.log('response', response)
                dispatch({type: 'UPDATE', tableData: response.PrepaidCodes})
                setTotalPages(Math.ceil(response.PageInfo.TotalRecords/response.PageInfo.PageSize));
                setTableDetails(response.PrepaidCodes);
                setDimmerLoader(false);
                isFirstRender.current = false;
            },
            onError: (error) => {
                handleError(error, enqueueSnackbar);
            }
        })
    }

    function getVoucherStatus(data) {
        if (data.IsRedeemed) {
            if (data.MaxRedeemCount === data.CurrentRedeemCount) {
                return "Fully Redeemed";
            } else {
                return "Partial Redeemed";
            }
        } else {
            if (!data.IsActive) {
                return "Disabled";
            } else {
                return "Created";
            }
        }
    }

    function getColor(value) {
        if (value === "Created") {
            return "green";
        } else if (value === "Partial Redeemed"){
            return "orange";
        } else if (value === "Disabled") {
            return "grey";
        } else {
            return "red";
        }
    }

    const viewVoucherDetails = (data) => (
        <Modal
            size="small"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header>
                <h3 style={Style.centraliseTitle}>
                    Prepaid Code Details
                    <Icon style={{float:'right', cursor: 'pointer'}} name="close" onClick={ () => setOpen(false)}/>
                </h3>
            </Modal.Header>
            <Modal.Content scrolling>
                <DisplayCodeDetails 
                    data={codeDetails} 
                    searchPage={false} 
                    refreshPage={()=>{
                        getTableData();
                        setOpen(false);
                    }}/>
            </Modal.Content>
            <Modal.Actions>
                {(codeDetails.PaymentToCollect && !codeDetails.PaymentReceived) && 
                    <Button color="blue" style={{width:'100%', marginLeft: 0}} onClick={() => setOpenApprovedModal(true)}>Approve Payment</Button>
                }
                {((codeDetails.PaymentReceived || (!codeDetails.PaymentReceived && !codeDetails.PaymentToCollect)) && codeDetails.CreatedForTenantID > 0  && (codeDetails.MaxRedeemCount !== codeDetails.CurrentRedeemCount)) &&
                    <Button color="blue" style={{width:'100%', marginLeft: 0}} onClick={()=> setOpenApplyCodeModal(true)}>Apply Prepaid Code</Button>
                }
            </Modal.Actions>
        </Modal>
    )

    const approveFormModal = () => <ApprovePaymentForm 
        openModal={setOpenApprovedModal} 
        data={codeDetails}
        onClick={(data) => approvePayment(codeDetails.Code, data)}
    />

    function approvePayment(code, checkboxValue) {
        let request = {
            Code: code,
            ForceApprove :checkboxValue
        }

        presenter.current.approvePayment({
            request,
            onSuccess: (response) => {
                setApprovedMessage("");
                setOpenApprovedModal(false);
                setOpenApprovedPaymentSuccessfulModal(true);
            },
            onError: (error) => {
                setOpenApprovedModal(false);
                setApprovedMessage(error);
                setOpenApprovedPaymentSuccessfulModal(true)
            }
        })
    }

    const approvedPaymentModal = () => <ApproveMessage 
        approvedMessage={approvedMessage} 
        openApproveMessageModal={openApprovedPaymentSuccessfulModal} 
        updateModal={setOpenApprovedPaymentSuccessfulModal} 
        onClick={() => approvedMessage.length === 0 ? refreshTable() : setOpenApprovedPaymentSuccessfulModal(false)}
    />

    function refreshTable() {
        updateCodeDetail(codeDetails.Code);
        setOpenApprovedPaymentSuccessfulModal(false);
        updateTable();
    }

    function getPaidStatus(paymentToCollect, paymentReceived) {
        if (paymentToCollect && !paymentReceived) {
            return PaymentStatus.UNPAID;
        } else {
            return PaymentStatus.PAID;
        }
    }

    function updateCodeDetail(code) {
        presenter.current.searchData({
            searchValue: code.trim(),
            onSuccess: (response) => {
                setCodeDetails(response);
            },
            onError: (error) => {
                handleError(error, enqueueSnackbar);
            }
        })
    }

    const applyPrepaidCodeModal = () => <ApplyPrepaidCodeModal
        openModal={openApplyCodeModal}
        updateOpenModal={(data)=>setOpenApplyCodeModal(data)}
        data={codeDetails}
        tenantName={tenantName}
        overrideCheckbox={(data)=> setOverride(data)}
        onSubmit={() => applyPrepaidCode()}
        errorMsg={errorMsg}
    />

    function applyPrepaidCode() {
        let request = {
            Code: codeDetails.Code,
            Override: override,
            TenantID: codeDetails.CreatedForTenantID,
            UserID: codeDetails.CreatedForUserID
        }

        setButtonLoad(true);
        presenter.current.applyPrepaidCode({
            request,
            onSuccess: (response) => {
                updateCodeDetail(codeDetails.Code);
                updateTable();
                setButtonLoad(false);
                setOpenApplyCodeModal(false);
                setErrorMsg(null);
                setOverride(false);
            },
            onError: (error) => {
                setOpenApplyCodeModal(true);
                setButtonLoad(false);
                setErrorMsg(error);
            }
        })
    }

    function getTenantName(data) {
        if (data.CreatedForEmail !== "") {
            presenter.current.getListOfTenants({
                searchValue: data.CreatedForEmail.trim(),
                onSuccess: (response) => {
                    let filterResult = response.filter((item) => item.TenantID === data.CreatedForTenantID);
                    if (filterResult.length > 0 ){
                        setTenantName(filterResult[0].TenantName)
                    } else {
                        setTenantName("-")
                    }
                },
                onError: (error) => {
                }
            })
        }
    }

    return (
        <div style={Style.mainDiv}>
            <SidebarLink/>
            <Sidebar.Pushable>
                <MainHeader/>
                <div style={Style.dashboardDiv} className="font">
                    <Segment.Group className='voucherSection'>
                        <Segment>
                            <h2 style={Style.centraliseTitle}>{getVoucherType()}</h2>
                        </Segment>
                        <Segment>
                            <div className='dataPadding'>
                                <div className='filterSection'>
                                    Filter By &nbsp;
                                    <Dropdown 
                                        clearable
                                        options={FilterOptions}
                                        value={filterBy}
                                        onChange={(e,data) => {
                                            setFilterBy(data.value);
                                            dispatch({ type: 'FILTER', selectedFilter: data.value, tableData: tableDetails})}
                                        }
                                    /> 
                                </div>
                                <div className='sortSection'>
                                    Sort By &nbsp;
                                    <Dropdown 
                                        options={SortOptions}
                                        value={sortBy}
                                        onChange={(e,data) => setSortBy(data.value)}
                                    /> 
                                </div>
                                <div className='tableScrollbar'>
                                    <Dimmer.Dimmable as={Table} sortable color='red' celled key='red' singleLine >
                                        <Table.Header>
                                            <Table.Row>
                                                <Dimmer inverted active={dimmerLoader}>
                                                    <Loader>Fetching Vouchers...</Loader>
                                                </Dimmer>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell
                                                    textAlign='center' 
                                                    style={Style.tableHeader}
                                                    sorted={column === TableColumn.Code ? direction : null}
                                                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: TableColumn.Code })}
                                                    width={3}
                                                >
                                                    {getVoucherType()}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell 
                                                    textAlign='center' 
                                                    style={Style.tableHeader}
                                                    className='tenPercentWidth'
                                                    sorted={column === TableColumn.Plan ? direction : null}
                                                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: TableColumn.Plan })}
                                                >
                                                    Plan
                                                </Table.HeaderCell>
                                                <Table.HeaderCell
                                                    textAlign='center'
                                                    style={Style.tableHeader}
                                                    className='tenPercentWidth'
                                                    sorted={column === TableColumn.Duration ? direction : null}
                                                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: TableColumn.Duration })}
                                                >
                                                    Duration
                                                </Table.HeaderCell>
                                                <Table.HeaderCell 
                                                    textAlign='center' 
                                                    style={Style.tableHeader} 
                                                    className='tenPercentWidth'
                                                    sorted={column === TableColumn.Amount ? direction : null}
                                                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: TableColumn.Amount })}
                                                >
                                                    Amount
                                                </Table.HeaderCell>
                                                <Table.HeaderCell 
                                                    textAlign='center' 
                                                    style={Style.tableHeader}
                                                    className='tenPercentWidth'
                                                    sorted={column === TableColumn.CreatedOn ? direction : null}
                                                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: TableColumn.CreatedOn })}
                                                >
                                                    Created On
                                                </Table.HeaderCell>
                                                <Table.HeaderCell 
                                                    textAlign='center' 
                                                    style={Style.tableHeader}
                                                    className='tenPercentWidth'
                                                    sorted={column === TableColumn.CreatedBy ? direction : null}
                                                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: TableColumn.CreatedBy })}
                                                >
                                                    Created By
                                                </Table.HeaderCell>
                                                <Table.HeaderCell className='statusWidth' textAlign='center' style={Style.tableHeader}>
                                                    Paid
                                                </Table.HeaderCell>
                                                <Table.HeaderCell className='statusWidth' textAlign='center' style={Style.tableHeader}>
                                                    Status
                                                </Table.HeaderCell>
                                                <Table.HeaderCell className='statusWidth' textAlign='center' style={Style.tableHeader}>
                                                    Action
                                                </Table.HeaderCell>
                                                <Table.HeaderCell 
                                                    textAlign='center' 
                                                    style={Style.tableHeader} 
                                                    className='tenPercentWidth'
                                                    sorted={column === TableColumn.CreatedFor ? direction : null}
                                                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: TableColumn.CreatedFor })}
                                                >
                                                    Customer Email
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {data.map((data) => (
                                                <Table.Row key={data.Code}>
                                                    <Table.Cell textAlign='center' >{data.Code}</Table.Cell>
                                                    <Table.Cell textAlign='center' >{data.Plan}</Table.Cell>
                                                    <Table.Cell textAlign='center' >{data.PrepaidDuration}</Table.Cell>
                                                    <Table.Cell textAlign='center' >{data.Amount}</Table.Cell>
                                                    <Table.Cell textAlign='center' >{getDateFormat(data.CreatedOn)}</Table.Cell>
                                                    <Table.Cell textAlign='center' >{data.CreatedBy}</Table.Cell>
                                                    <Table.Cell textAlign='center' >{getPaidStatus(data.PaymentToCollect, data.PaymentReceived)}</Table.Cell>
                                                    <Table.Cell textAlign='center' style={{color: getColor(getVoucherStatus(data))}}>{getVoucherStatus(data)}</Table.Cell>
                                                    <Table.Cell textAlign='center' className='actionView'>
                                                        <Button onClick={() => {setOpen(true); setCodeDetails(data); getTenantName(data)}}>View</Button>
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center' >{data.CustomerReference.trim().length>0 ? data.CustomerReference : "-"} </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Dimmer.Dimmable>
                                </div>
                                <div className='paginationSection'>
                                    <Pagination
                                        activePage={pageNumber}
                                        onPageChange={(event, data) => {
                                            setPageNumber(data.activePage); 
                                            setFilterBy("");
                                    }}
                                    totalPages={totalPages}
                                    />
                                     <Dropdown
                                        className="pageSizeSection"
                                        options={PageSize}
                                        defaultValue="15"
                                        onChange={(e, data) => setPageSize(Number(data.value))}
                                    />
                                    <span className="pageSizeText">Rows per Page</span>
                                </div>
                                {open && viewVoucherDetails()}
                                {openApprovedModal && approveFormModal()}
                                {openApprovedPaymentSuccessfulModal && approvedPaymentModal()}
                                {openApplyCodeModal && applyPrepaidCodeModal()}
                            </div>
                        </Segment>
                    </Segment.Group>
                </div>
            </Sidebar.Pushable>
            
        </div>
    )

}

function mapStateToProps(state = {userRole:{}}, ownProps) {
    let userRole = getUserRole(state);
    return{userRole:userRole};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewVoucher);