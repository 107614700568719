const style = {};
const sidebarWidth = '250';

style.menu = {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    bottom: 0,
    left: 0,
    width: sidebarWidth,
    background: '#1B1C1D',
    overflowX: 'hidden',
};

style.mainDiv = {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    flex: 1,
    overflowX: 'hidden',
};
style.sideBar = {
    background: '#1b1c1d',
    width: '15em',
    overflow: 'hidden',
};
style.companyDiv = {
    display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '1.5em',
    paddingTop: '1.5em',
    paddingLeft: '1.2em'
}
style.companyTitle = {
    marginTop: 0,
    fontSize: '1.3rem',
    fontWeight: 'bolder',
    color:'white'
}
style.subitle = {
    paddingLeft: '1.1em',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    color:'white',
    cursor: 'default'
}
style.toolbar = {
    width: '100vw',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1.2em',
    border: '0 0 2px black',
    boxShadow: '0 0 2px grey',
    backgroundColor: '#ffffff',
};
style.dashboardDiv = {
    width: 'calc(100vw - 15em)',
    height: '100vh',
    flex: 1,
    overflowX: 'hidden',
    paddingTop: '2em',
    paddingLeft: '2em',
    paddingBottom: '2em',
    paddingRight: '0.6em',
    backgroundColor: '#eef1f6',
};

style.tableHeader = {
    backgroundColor: '#e1f5fe',
    color: 'black',
    fontWeight: '800',
};
style.font = {
    fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
    fontSize: '16px',
    fontWeight: '500',
};

style.sidebarDiv = {
    display: 'grid', 
    paddingTop: '1rem',
    fontSize:'1rem',
};

style.centraliseTitle = {
    textAlign: 'center'
}

style.sectionHeader = {
    opacity:'0.7'
}

style.wordWrap = {
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word'
}

export default style;
