import {useEffect, useRef, useState} from 'react';
import Style from '../../Style';
import SidebarLink from "../sidebar/SidebarLink";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { SearchField } from '../common/SearchField';
import {handleError} from "../../utilities/AlertableErrorHandler";
import {useSnackbar} from "notistack";
import { useHistory } from "react-router-dom";

import {Sidebar, Segment, Divider, Table, Button, Loader, Dimmer, Message, Grid, Container} from "semantic-ui-react";

import { VerifyUserPagePresenter } from './VerifyUserPagePresenter';
import MainHeader from '../mainHeader/MainHeader';

function VerifyUserPage(props) {
    const [searchValue, setSearchValue] = useState("");
    const [loader, setLoader] = useState(false);
    const [verifyEmailResponse, setVerifyEmailResponse] = useState(undefined);

    const presenter = useRef(new VerifyUserPagePresenter);
    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    useEffect(() => {
        if (props.location.state?.email !== undefined) {
            setSearchValue(props.location.state?.email);
        }
    },[])

    useEffect(() => {
        if (searchValue.length > 1) {
            setLoader(true);
            verifyEmail();
        }
    }, [searchValue] )


    function verifyEmail() {
        presenter.current.verifyUserEmail({
            searchValue: searchValue.trim(),
            onSuccess: (response) => {
                setLoader(false);
                setVerifyEmailResponse(response)
            },
            onError: (error) => {
                setLoader(false);
                if (error) {
                    error.message = error.statusText;
                    setVerifyEmailResponse(error.message);
                    handleError(error, enqueueSnackbar);
                }
            }
        })
    }

    return (
        <div style={Style.mainDiv}>
            <SidebarLink/>
            <Sidebar.Pushable>
                <MainHeader/>
                <div style={Style.dashboardDiv} className="font">
                    <Grid style={{paddingLeft:"27%"}}>
                        <Grid.Row>
                        <Segment.Group style={{width:"63%"}}>
                            <Segment>
                                <h2 style={Style.centraliseTitle}>Verify User with Email</h2>
                            </Segment>
                            <Segment>
                                <Grid>
                                    <Grid.Row style={{paddingBottom:'0rem'}}>
                                        <div style={{paddingLeft:'15px', width:'100%'}}>
                                            <SearchField getSearchValue={setSearchValue} clearValue={setVerifyEmailResponse} searchValue={searchValue} email={true}/>
                                        </div>
                                    </Grid.Row>
                                        {loader ? 
                                            <Grid.Row style={{height:'90px'}}>
                                                <div>
                                                    <Loader active={loader}>Verifying User</Loader>
                                                </div>
                                            </Grid.Row> : 
                                            <Grid.Row>
                                               {verifyEmailResponse !== undefined && 
                                                    <div style={{width:'96%', marginLeft:'2%'}}>
                                                        {verifyEmailResponse !== null ? 
                                                        <Container> 
                                                             
                                                                <div>
                                                                    <p>
                                                                        {verifyEmailResponse}
                                                                    </p>
                                                                </div>
                                                           
                                                        </Container> : 
                                                            <Container>
                                                                <Divider/>
                                                                <Message warning>
                                                                    <Message.Header>Nothing verified</Message.Header>
                                                                </Message>
                                                            </Container>
                                                        }
                                                    </div>
                                                }
                                            </Grid.Row>
                                        }
                                    </Grid>
                            </Segment>
                        </Segment.Group>
                        </Grid.Row>
                    </Grid>
                </div>
            </Sidebar.Pushable>
        </div>
    )
}

function mapStateToProps(state = {}, ownProps) {
    return{};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUserPage);