import {
    SAVE_REFRESH_TOKEN_INFO,
    SAVE_TOKEN_INFO,
    SAVE_USER_INFO,
    SAVE_USER_ROLE

} from "./actionTypes";

export const saveUser = user => ({
    type: SAVE_USER_INFO,
    payload: {
        user: user
    }
})

export const saveToken = token => ({
    type: SAVE_TOKEN_INFO,
    payload: {
        token: token
    }
})

export const saveRefreshToken = refreshToken => ({
    type: SAVE_REFRESH_TOKEN_INFO,
    payload: {
        refreshToken: refreshToken
    }
})

export const saveUserRole = userRole => ({
    type: SAVE_USER_ROLE,
    payload: {
        userRole: userRole
    }

})