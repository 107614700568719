import React, {useEffect, useState} from 'react';
import Style from '../../Style';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SidebarLink from "../sidebar/SidebarLink";

import {Button, Sidebar, Modal, Select, Segment, Form, Input, TextArea, Table} from "semantic-ui-react";

import MainHeader from '../mainHeader/MainHeader';

function CreateDiscountCode(props) {
    return (
        <div style={Style.mainDiv}>
            <SidebarLink/>
            <Sidebar.Pushable>
                <MainHeader />
                <div style={Style.dashboardDiv} className="font">
                <Segment className='voucherSection'>
                    Coming soon..
                </Segment>
                </div>
            </Sidebar.Pushable>
        </div>
    )

}

function mapStateToProps(state = {}, ownProps) {
    return{};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDiscountCode);
