import {
    SAVE_REFRESH_TOKEN_INFO,
    SAVE_TOKEN_INFO,
    SAVE_USER_INFO,
    SAVE_USER_ROLE
} from "./actionTypes";

const initialState = {
    user: {},
    token: undefined,
    tenantList: [],
    metaData: {}
};

export default function session(state = initialState, action) {
    switch (action.type) {
        case SAVE_USER_INFO: {
            const {user} = action.payload;
            return {
                ...state,
                user: user
            };
        }
        case SAVE_TOKEN_INFO: {
            const {token} = action.payload;
            return {
                ...state,
                token: token
            };
        }
        case SAVE_REFRESH_TOKEN_INFO: {
            const {refreshToken} = action.payload;
            return {
                ...state,
                refreshToken: refreshToken
            };
        }
        case SAVE_USER_ROLE: {
            const {userRole} = action.payload;
            return {
                ...state,
                userRole: userRole
            };
        }
        default: {
            return state;
        }
    }
}
