import React from "react";

import {getSelectedTenant, getTenantList, getUserFullName} from "../../redux/session/selector";
import {connect} from "react-redux";
import {AuthRepository} from "../../api/repo/AuthRepository";
import {gotoLoginPage} from "../../api/utilities/NavigationUtils";
import {bindActionCreators} from "redux";
import { Dropdown, Icon} from "semantic-ui-react";
import Style from "../../Style"
import css from "./style.css"
import main from "./../../styles/mainStyles.css"
import {clearAllStorageData} from "../../utilities/CommonUtils";

function MainHeader(props) {
    const authRepo = new AuthRepository();

    return (
        <div style={{paddingRight:"25px", paddingBottom:"0.7em", paddingTop:"0.7em", display:'flex', flexDirection:'row', justifyContent:'end',}}>
            <Icon name='user'/>
            <span><h3>{props.fullName}</h3></span>
        </div>

    );

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state = {}, ownProps) {
    const fullName = getUserFullName(state);
  return {fullName: fullName};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
