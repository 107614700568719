import { UserRepository } from "../../api/repo/UserRepository";

export class SetUserPasswordPresenter {
    userApiRepo = new UserRepository;

    setUserPassword(args) {
        this.userApiRepo.setUserPassword(args.payload).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }
}
