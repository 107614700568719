import {useEffect, useState} from 'react';
import {Modal, Button, Checkbox} from "semantic-ui-react";

export function ApproveMessage(props) {

    return (
        <Modal
            size="tiny"
            open={props.openApproveMessageModal}
            onClose={() => props.updateModal(false)}
            onOpen={() => props.updateModal(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header>
                <h3>{props.approvedMessage.length > 1 ? "Payment Error" : "Payment Approved"}</h3>
            </Modal.Header>
            <Modal.Content>
                {props.approvedMessage.length > 1 ? props.approvedMessage: "Code can be redeemed now."}
            </Modal.Content>
            <Modal.Actions>
                <Button color='blue' onClick={() => props.onClick && props.onClick()}>Okay</Button>
            </Modal.Actions>
        </Modal>
    )
}