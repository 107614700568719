import React, {useEffect, useRef, useState} from 'react';
import Style from '../../Style';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SidebarLink from "../sidebar/SidebarLink";
import "./style.css";

import {Button, Sidebar, Modal, Select, Segment, Form, Input, Table, Checkbox, Grid, Message, Divider, Label, Popup} from "semantic-ui-react";
import { FilterOptions, FilterValues, PaymentOptions, PaymentGatewayOptions } from '../../constant/Constant';
import {CreatePrepaidVoucherPresenter} from "./CreatePrepaidVoucherPresenter";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { getVoucherType } from '../../utilities/CommonUtils';

import MainHeader from '../mainHeader/MainHeader';

import { CreatePrepaidVoucherCode } from '../common/createNewPrepaidVoucher/CreatePrepaidVoucherCode';

function CreatePrepaidVoucher(props) {

    return (
        <div style={Style.mainDiv}>
            <SidebarLink/>
            <Sidebar.Pushable>
                <MainHeader />
                <div style={Style.dashboardDiv} className="font">
                    <Grid centered >
                        <Grid.Column tablet={16} computer={9}>
                            <Segment className='voucherSection'>
                                <h2 style={Style.centraliseTitle}>Create a New Prepaid Code</h2>
                                <CreatePrepaidVoucherCode subscriptionPage={false}/>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </div>
            </Sidebar.Pushable>

        </div>
    )

}

function mapStateToProps(state = {}, ownProps) {
    return{};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePrepaidVoucher);
