import {AuthApiClient} from "../client/AuthApiClient";

export class AuthRepository {
    authApiClient = new AuthApiClient()

    async checkLoginStatus() {
        return await this.authApiClient.checkLoginStatus()
    }

    async checkAdminStatus() {
        return await this.authApiClient.checkAdminStatus()
    }
}
