import { useRef, useState } from 'react';
import { SetUserPasswordPresenter } from './SetUserPasswordPresenter';
import Style from '../../Style';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SidebarLink from '../sidebar/SidebarLink';
import { Button, Divider, Form, Grid, Input, Message, Segment, Sidebar } from 'semantic-ui-react';
import MainHeader from '../mainHeader/MainHeader';
import { isValidEmail } from '../../utilities/CommonUtils';
import { useSnackbar } from 'notistack';
import { handleSuccess } from '../../utilities/AlertableSuccessHandler';
import { handleError } from '../../utilities/AlertableErrorHandler';

function SetUserPasswordPage(props) {
    const presenter = useRef(new SetUserPasswordPresenter);
    const {enqueueSnackbar} = useSnackbar();

    const [formState, setFormState] = useState({
        userName: "",
        password: ""
    });
    const [errorState, setErrorState] = useState({
        userName: false,
        password: false
    });
    const [loader, setLoader] = useState(false);
    const [successMsg, setSuccessMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const clearForm = () => {
        setFormState({
            userName: "",
            password: ""
        });

        setSuccessMsg(null);
        setErrorMsg(null);
    }

    const isInvalidateForm = () => {
        let invalid = false;

        const newErrorState = {...errorState};
        if (!isValidEmail(formState.userName)) {
            newErrorState.userName = true;
            invalid = true;
        }

        if (formState.password === "") {
            newErrorState.password = true;
            invalid = true;
        }

        setErrorState(newErrorState);

        return invalid;
    }

    const handleSubmit = () => {
        if (isInvalidateForm()) {
            return;
        }

        setLoader(true);
        presenter.current.setUserPassword({
            payload: {
                confirmationCode: 123456,
                userName: formState.userName,
                password: formState.password
            },
            onSuccess: (response) => {
                setLoader(false);
                handleSuccess("The password has been set successfully.", enqueueSnackbar);
                setSuccessMsg("Success");
            },
            onError: (error) => {
                setLoader(false);
                if (error && error.data) {
                    handleError({ message: error.data.errorMessage }, enqueueSnackbar);
                }
                setErrorMsg("Error");
            }
        })
    }

    return (
        <div style={Style.mainDiv}>
            <SidebarLink/>
            <Sidebar.Pushable>
                <MainHeader/>
                <div style={Style.dashboardDiv} className="font">
                    <Grid style={{paddingLeft:"27%"}}>
                        <Grid.Row>
                        <Segment.Group style={{width:"63%"}}>
                            <Segment>
                                <h2 style={Style.centraliseTitle}>Set Password</h2>
                            </Segment>
                            <Segment>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field
                                            control={Input}
                                            label={<label>Email <label className='requiredStyle'>*</label></label>}
                                            placeholder='Ex. aa@bb.com'
                                            value={formState.userName}
                                            error={errorState.userName}
                                            onChange={(e, data) => {
                                                setFormState({...formState, userName: data.value});
                                                setSuccessMsg(null);
                                                setErrorMsg(null);

                                                if (data.value) {
                                                    setErrorState({...errorState, userName: !isValidEmail(data.value)});
                                                } else {
                                                    setErrorState({...errorState, userName: false});
                                                }
                                            }}
                                        />
                                        <Form.Field
                                            control={Input}
                                            type={<input type='password'/>}
                                            label={
                                                <label>
                                                    Password <label className='requiredStyle'>*</label>
                                                    <small> (Min. 8 characters)</small>
                                                </label>
                                            }
                                            placeholder='Password'
                                            value={formState.password}
                                            error={errorState.password}
                                            onChange={(e, data) => {
                                                setFormState({...formState, password: data.value});
                                                setSuccessMsg(null);
                                                setErrorMsg(null);

                                                if (data.value) {
                                                    setErrorState({...errorState, password: String(data.value).length < 8});
                                                } else {
                                                    setErrorState({...errorState, password: false});
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                    <Divider section/>
                                    <Button.Group className='formGroup'>
                                        <Button onClick={() => clearForm()}>Clear</Button>
                                        <Button.Or />
                                        <Button color='blue' loading={loader} onClick={() => handleSubmit()}>Submit</Button>
                                    </Button.Group>
                                </Form>
                                {successMsg && <Message positive>
                                    <Message.Header>{successMsg}</Message.Header>
                                </Message>}
                                {errorMsg && <Message negative>
                                    <Message.Header>{errorMsg}</Message.Header>
                                </Message>}
                            </Segment>
                        </Segment.Group>
                        </Grid.Row>
                    </Grid>
                </div>
            </Sidebar.Pushable>
            
        </div>
    );
}

function mapStateToProps(state = {}, ownProps) {
    return{};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SetUserPasswordPage);
