import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import ViewVoucherDetails from "./view_voucher_details/ViewVoucherDetails";
import ViewSubscription from "./view_subscription/ViewSubscription";

// components
import Layout from "./layout/Layout";

export default function App() {
  // global
  return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/view_prepaid_code" />} />
          <Route path="/view/:voucherCode" component={ViewVoucherDetails}/>
          <Route path="/subscription/view_details" component={ViewSubscription}/>
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </BrowserRouter>
  );


  function PrivateRoute({ component, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                React.createElement(component, props)
            }
        />
    );
  }
}
