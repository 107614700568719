import { SubscriptionApiClient } from "../client/SubscriptionApiClient";

export class SubscriptionRepository {
    subscriptionApiClient = new SubscriptionApiClient;

    async getTenantList(args) {
        return await this.subscriptionApiClient.getTenantList(args);
    }

    async getOrganisationDetails(args) {
        return await this.subscriptionApiClient.getOrganisationDetails(args);
    }

    async applyPrepaidCode(args) {
        return await this.subscriptionApiClient.applyPrepaidCode(args);
    }

    async applyDiscountCode(args) {
        return await this.subscriptionApiClient.applyDiscountCode(args);
    }

}