import axios from "axios";

export default class AxiosSharedInstance {

    axiosInstance

    static getInstance() {
        if (AxiosSharedInstance.obj == null) {
            AxiosSharedInstance.obj = new AxiosSharedInstance()

        }
        return AxiosSharedInstance.obj
    }

    getAxiosInstance() {
        if (this.axiosInstance === undefined) {
            this.axiosInstance = axios.create({
                timeout: 60 * 1000
            });
        }
        return this.axiosInstance
    }

}
