import { PrepaidCodeRepository } from "../../api/repo/PrepaidCodeRepository";
import { SubscriptionRepository } from "../../api/repo/SubscriptionRepository";
export class SearchPrepaidCodePresenter {
    prepaidCodeRepo = new PrepaidCodeRepository;
    subscriptionRepo = new SubscriptionRepository;

    searchData(args) {
        this.prepaidCodeRepo.searchData(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

    approvePayment(args) {
        this.prepaidCodeRepo.approvePayment(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

    getListOfTenants(args) {
        this.subscriptionRepo.getTenantList(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

    applyPrepaidCode(args) {
        this.subscriptionRepo.applyPrepaidCode(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }
}