import { UserRepository } from "../../api/repo/UserRepository";

export class VerifyUserPagePresenter {
    userApiRepo = new UserRepository;

    verifyUserEmail(args) {
        this.userApiRepo.verifyUserEmail(args.searchValue).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

}