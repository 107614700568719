import {AuthToken} from "../api/client/BaseApiClient";
import {isLocalHost} from "./CommonUtils";

export function requestHeaderForGET(appendIsLocalParam = true) {
    let request = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Origin':'*',
         // "x-access-token": AuthToken.token,
    };
    return request
    // if(isLocalHost() && appendIsLocalParam) {
    //     return {...request, is_local: "true"}
    // } else {
    //     return request
    // }
}

export function requestHeaderForImage() {
    return {
        Accept: 'application/json',
        'Content-Type': "multipart/form-data",
         // "x-access-token": AuthToken.token
    };
}

export function requestHeaderForAnyGET(appendIsLocalParam = true) {
    let request = {
        Accept: '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Origin':'*',
         // "x-access-token": AuthToken.token,
    };
    return request
    // if(isLocalHost() && appendIsLocalParam) {
    //     return {...request, is_local: "true"}
    // } else {
    //     return request
    // }
}

export function buildQueryParam(query) {
    let url = ''
    query.forEach((data) => {
        if (data.key && data.key.length > 0 && data.value && data.value.length > 0) {
            url = url + `${url.length === 0 ? `?` : `&`}` + `${data.key}=${data.value}`
        }
    })
    return url
}
