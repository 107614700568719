import {useEffect, useState} from 'react';
import {Modal, Button, Checkbox, Popup, Label, Icon, Message} from "semantic-ui-react";
import Style from '../../Style';

export function ApplyPrepaidCodeModal(props) {

    const [buttonLoad, setButtonLoad] = useState(false);

    useEffect(() => {
        setButtonLoad(false);
    },[props?.data])

    return <Modal
            size="tiny"
            open={props.openModal}
            onClose={() => props.updateOpenModal(false)}
            onOpen={() => props.updateOpenModal(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header>
                <h3 style={Style.centraliseTitle}>
                    Apply Prepaid Code
                    <Icon style={{float:'right', cursor: 'pointer'}} name="close" onClick={ () => props.updateOpenModal(false)}/>
                </h3>
            </Modal.Header>
            <Modal.Content>
                {props.data.CreatedForTenantID !== 0 &&
                    <div>
                        <span>Do you still want to apply <i>{props.data.Code}</i> Prepaid Code for the below organisation : </span>
                        <p className="orgNameText"><b>{props.tenantName}</b></p>
                        <Checkbox 
                            label={ 
                                <label>
                                    Override existing codes
                                    &nbsp;
                                    <Popup content='All existing codes applied will be removed if codes are of different plans' trigger={<Label circular>?</Label>} />
                                </label> 
                            }
                            checked={props.override}
                            onChange={()=>props.overrideCheckbox(!props.override)}
                        />
                    </div>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button color="blue" loading={buttonLoad} onClick={() => {setButtonLoad(true); props.onSubmit && props.onSubmit()}}>Confirm</Button>
                {props.errorMsg && 
                    <Message negative>
                        <Message.Header>{props.errorMsg}</Message.Header>
                    </Message>
                }
            </Modal.Actions>
        </Modal>
}