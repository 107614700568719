import {useEffect, useState} from 'react';
import {Modal, Button, Checkbox, Popup, Label} from "semantic-ui-react";
import Style from '../../Style';

export function ApprovePaymentForm(props) {

    const [overrideCheckbox, setOverrideCheckbox] = useState(false);
    const [buttonLoad, setButtonLoad] = useState(false);

    useEffect(() => {
        setButtonLoad(false);
    },[props?.data])

    return (
        <Modal
            size="tiny"
            open={props.openModal}
            onClose={() => props.openModal(false)}
            onOpen={() => props.openModal(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header>
                <h3 style={Style.centraliseTitle}>Approve Prepaid Code</h3>
            </Modal.Header>
            <Modal.Content>
                <span>Are you sure you want to approve this prepaid code?</span>
                <div style={{paddingTop:'10px'}}>
                    <Checkbox
                        checked={overrideCheckbox}
                        onChange={()=>setOverrideCheckbox(!overrideCheckbox)}
                        label={<label>
                            Approve Payment
                            &nbsp;
                            <Popup content='Approve without invoice payment validation.' trigger={<Label circular>?</Label>} />                                                      
                        </label>}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div style={{width:'100%', marginLeft: 0}}>
                    <Button style={{width:'48%', float:'left'}} onClick={() => props.openModal(false)}>Close</Button>
                    <Button color="blue" style={{width:'48%'}} loading={buttonLoad} onClick={() => {setButtonLoad(true); props.onClick && props.onClick(overrideCheckbox)}}>Confirm</Button>
                </div>
            </Modal.Actions>
        </Modal>
    )
}