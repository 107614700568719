import { PrepaidCodeRepository } from "../../api/repo/PrepaidCodeRepository";

export class RevokeConfirmationPopupPresenter{
    prepaidCodeRepo = new PrepaidCodeRepository;

    revokePrepaidCode(args) {
        this.prepaidCodeRepo.revokePrepaidCode(args.request).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }
}