import { useRef, useState } from "react";
import {Modal, Button} from "semantic-ui-react";
import Style from '../../Style';
import { RevokeConfirmationPopupPresenter } from "./RevokeConfirmationPopupPresenter";

export function RevokeConfirmationPopup(props) {

    const [title, setTitle] = useState(props.disabled ? "Revoke and Disabled" : "Revoke");

    const presenter = useRef(new RevokeConfirmationPopupPresenter());

    function revokeCode() {
        let request= {
            Code :   props.voucherCode,
            TenantIDs : [ props.tenantID ], 
            Disable : props.disabled
        }

        presenter.current.revokePrepaidCode({
            request,
            onSuccess: (resposne) => {
                props.updateModal(false); 
                props.callRevoke(false);
            },
            onError: (error) => {
                console.log("error", error)
            }
        })

    }

    return(
        <Modal
            size='tiny'
            open={props.openModal}
            onClose={() => props.updateModal(false)}
            onOpen={() => props.updateModal(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header style={Style.centraliseTitle}>
                {title} Prepaid Code {props.voucherCode}
            </Modal.Header>
            <Modal.Content>
                Are you sure you want to {title.toLowerCase()} this prepaid code from {props.organisationName} organisation?
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    props.updateModal(false);
                    props?.removeSelectedTenant();
                }}>
                    Cancel
                </Button>
                <Button
                    color="red"
                    content="Yes"
                    loading={props.buttonLoad}
                    onClick={() => {
                        revokeCode();
                    }}
                />
            </Modal.Actions>
        </Modal>
    )
}