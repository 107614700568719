import {AuthRepository} from "../../../api/repo/AuthRepository";
import {UserRepository} from "../../../api/repo/UserRepository";

export class LayoutPresenter {

    private authRepo = new AuthRepository();
    private userRepo = new UserRepository();

    checkLoginStatus(args: any) {
        this.authRepo.checkLoginStatus().then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                console.log("Error::" + JSON.stringify(response))
                args.onError && args.onError(error)}
        });
    }

    getUserDetails(args: any) {
        this.userRepo.getUserDetails(args.userId).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)}
        });
    }

    checkAdminStatus(args: any) {
        this.authRepo.checkAdminStatus().then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }
}
