import Style from "../../Style";
import {Image, Container, Button, Icon, Dropdown, Popup, Divider, Accordion} from "semantic-ui-react";
import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {VoucherType, SideBarName, SideBarValue} from "../../constant/Constant";
import {SidebarLinkPresenter} from "./SidebarLinkPresenter";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useSnackbar, withSnackbar} from "notistack";
import {clearAllStorageData} from "../../utilities/CommonUtils";
import {AuthRepository} from "../../api/repo/AuthRepository";
import {getUserFullName} from "../../redux/session/selector";

function SidebarLink(props) {
  const presenter = useRef(new SidebarLinkPresenter());
  const [activeTab, setActiveTab] = useState("");
  const {enqueueSnackbar} = useSnackbar();
  const authRepo = new AuthRepository();

  const [activeIndex, setActiveIndex] = useState();

  useEffect(() => {
    getPagePath();
  }, []);

  function getPagePath() {
    if (window.location.href.indexOf("view_prepaid_code") > -1 || window.location.href.indexOf("view") > -1) {
      setActiveTab(SideBarValue.VIEW_PREPAID_VOUCHER);
      setActiveIndex(0);
    } 
    if (window.location.href.indexOf("create_prepaid_code") > -1) {
      setActiveTab(SideBarValue.CREATE_PREPAID_CODE);
      setActiveIndex(0);
    } 
    if (window.location.href.indexOf("search_prepaid_code") > -1) {
      setActiveTab(SideBarValue.SEARCH_PREPAID_CODE);
      setActiveIndex(0);
    }
    if (window.location.href.indexOf("view_discount_code") > -1) {
      setActiveTab(SideBarValue.VIEW_DISCOUNT_CODE);
      setActiveIndex(1);
    }
    if (window.location.href.indexOf("create_discount_code") > -1) {
      setActiveTab(SideBarValue.CREATE_DISCOUNT_CODE);
      setActiveIndex(1);
    } 
    if (window.location.href.indexOf("search_discount_code") > -1) {
      setActiveTab(SideBarValue.VIEW_DISCOUNT_CODE);
      setActiveIndex(1);
    }
    if (window.location.href.indexOf("subscription") > -1) {
      setActiveTab(SideBarValue.SUBSCRIPTION);
      setActiveIndex(2);
    }
    if (window.location.href.indexOf("user") > -1) {
      setActiveTab('Unlock_Account');
      setActiveIndex(3);
    }
    if (window.location.href.indexOf("verifyemail") > -1) {
      setActiveTab('Verify_Email');
      setActiveIndex(3);
    }
    if (window.location.href.indexOf("setpassword") > -1) {
      setActiveTab('Set_Password');
      setActiveIndex(3);
    }
    if (window.location.href.indexOf("disablemfa") > -1) {
      setActiveTab('Disable_MFA');
      setActiveIndex(3);
    }
  }

  function handleClick(data) {
    const newIndex = activeIndex === data.index ? -1 : data.index
    setActiveIndex(newIndex);
  }

  const panels = [
    {
      key:"prepaidCodes",
      title: {
        content: <div style={Style.subitle}>Prepaid Code</div>
      },
      content: {
        content: <div
        style={{
          fontWeight: activeTab === SideBarValue.VIEW_PREPAID_VOUCHER ? "bold" : undefined,
          width: "100%",
          paddingLeft: '1.2em',
          marginBottom: '0.3em',
          marginTop: '0.3em',
        }}
        className='menu'
        onClick={() => {
          setActiveTab(SideBarValue.VIEW_PREPAID_VOUCHER);
          clearAllStorageData();
        }}>
        <Link to='/view_prepaid_code'>
          <div 
            style={Style.sidebarDiv,{
              color: activeTab === SideBarValue.VIEW_PREPAID_VOUCHER ?'white': 'rgba(255,255,255,.5)'
            }} 
            className='flex'
          >
            {SideBarName.VIEW}
          </div>
        </Link>
      </div>
      }
    }
  ]

  return (
    <div style={Style.sideBar}>
      <div style={{height:'93%'}}>
        <div style={Style.companyDiv}>
          <div style={Style.companyTitle}>Subscription Admin</div>
        </div>
        <Divider style={{margin:'0px', borderTopWidth: '2px', borderBottomWidth: '2px'}}/>
        <Accordion>
          <Accordion.Title 
            style={Style.subitle}
            index={0}
            onClick={(e, data) => handleClick(data)}
          >
            <Icon name={activeIndex === 0 ? 'caret down' : 'dropdown'} />
              {VoucherType.PREPAID_CODES}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <div
              style={{
                fontWeight: activeTab === SideBarValue.VIEW_PREPAID_VOUCHER ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
                marginBottom: '0.3em',
              }}
              className='menu'
              onClick={() => {
                setActiveTab(SideBarValue.VIEW_PREPAID_VOUCHER);
                clearAllStorageData();
              }}
            >
              <Link to='/view_prepaid_code'>
                <div 
                  style={Style.sidebarDiv,{
                    color: activeTab === SideBarValue.VIEW_PREPAID_VOUCHER ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {SideBarName.VIEW}
                </div>
              </Link>
            </div>
            <div
              style={{
                fontWeight: activeTab === SideBarValue.CREATE_PREPAID_CODE ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
                marginBottom: '0.3em'
              }}
              className='menu'
              onClick={() => {
                setActiveTab(SideBarValue.CREATE_PREPAID_CODE);
                clearAllStorageData();
              }}
            >
              <Link to='/create_prepaid_code'>
                <div 
                  style={Style.sidebarDiv, {
                    color: activeTab === SideBarValue.CREATE_PREPAID_CODE ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {SideBarName.CREATE}
                </div>
              </Link>
            </div>
            <div 
              style={{
                fontWeight: activeTab === SideBarValue.SEARCH_PREPAID_CODE ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
              }}
              className='menu'
              onClick={() => {
                setActiveTab(SideBarValue.SEARCH_PREPAID_CODE);
                clearAllStorageData();
            }}>
              <Link to='/search_prepaid_code'>
                <div 
                  style={Style.sidebarDiv,{
                    color: activeTab === SideBarValue.SEARCH_PREPAID_CODE ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {SideBarName.SEARCH}
                </div>
              </Link>
            </div>
          </Accordion.Content>

          <Accordion.Title 
            style={Style.subitle}
            index={1}
            onClick={(e, data) => handleClick(data)}
          >
            <Icon name={activeIndex === 1 ? 'caret down' : 'dropdown'} />
                  {VoucherType.DISCOUNT_CODES}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <div
              style={{
                fontWeight: activeTab === SideBarValue.VIEW_DISCOUNT_CODE ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
                marginBottom: '0.3em',
              }}
              className='menu'
              onClick={() => {
                setActiveTab(SideBarValue.VIEW_DISCOUNT_CODE);
                clearAllStorageData();
              }}
            >
              <Link to='/view_discount_code'>
                <div 
                  style={Style.sidebarDiv,{
                    color: activeTab === SideBarValue.VIEW_DISCOUNT_CODE ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {SideBarName.VIEW}
                </div>
              </Link>
            </div>
            <div
              style={{
                fontWeight: activeTab === SideBarValue.CREATE_DISCOUNT_CODE ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
                marginBottom: '0.3em',
                marginTop: '0.3em',
              }}
              className='menu'
              onClick={() => {
                setActiveTab(SideBarValue.CREATE_DISCOUNT_CODE);
                clearAllStorageData();
              }}
            >
              <Link to='/create_discount_code'>
                <div 
                  style={Style.sidebarDiv, {
                    color: activeTab === SideBarValue.CREATE_DISCOUNT_CODE ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {SideBarName.CREATE}
                </div>
              </Link>
            </div>
            <div 
              style={{
                fontWeight: activeTab === SideBarValue.SEARCH_DISCOUNT_CODE ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
                marginBottom: '1em'
              }}
              className='menu'
              onClick={() => {
                setActiveTab(SideBarValue.SEARCH_DISCOUNT_CODE);
                clearAllStorageData();
              }}
            >
              <Link to='/search_discount_code'>
                <div 
                  style={Style.sidebarDiv, {
                    color: activeTab === SideBarValue.SEARCH_DISCOUNT_CODE ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {SideBarName.SEARCH}
                </div>
              </Link>
            </div>
          </Accordion.Content>

          <Accordion.Title 
            style={Style.subitle}
            index={2}
            onClick={(e, data) => handleClick(data)}
          >
            <Icon name={activeIndex === 2 ? 'caret down' : 'dropdown'} />
            Subscriptions
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <div 
              style={{
                fontWeight: activeTab === SideBarValue.SEARCH ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
                marginBottom: '1em'
              }}
              className='menu'
              onClick={() => {
                setActiveTab(SideBarValue.SEARCH);
                clearAllStorageData();
              }}
            >
              <Link to='/subscription'>
                <div 
                  style={Style.sidebarDiv, {
                    color: activeTab === SideBarValue.SEARCH ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {SideBarName.SEARCH}
                </div>
              </Link>
            </div>
          </Accordion.Content>

          <Accordion.Title 
            style={Style.subitle}
            index={3}
            onClick={(e, data) => handleClick(data)}
          >
            <Icon name={activeIndex === 3 ? 'caret down' : 'dropdown'} />
              {VoucherType.USER_MGMT}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            <div 
              style={{
                fontWeight: activeTab === 'Unlock_Account' ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
                marginBottom: '1em'
              }}
              className='menu'
              onClick={() => {
                setActiveTab('Unlock_Account');
                clearAllStorageData();
              }}
            >
              <Link to='/user'>
                <div 
                  style={Style.sidebarDiv, {
                    color: activeTab === 'Unlock_Account' ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {'Unlock Account'}
                </div>
              </Link>
            </div>
            <div 
              style={{
                fontWeight: activeTab === 'Verify_Email' ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
                marginBottom: '1em'
              }}
              className='menu'
              onClick={() => {
                setActiveTab('Verify_Email');
                clearAllStorageData();
              }}
            >
              <Link to='/verifyemail'>
                <div 
                  style={Style.sidebarDiv, {
                    color: activeTab === 'Verify_Email' ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {'Verify Email'}
                </div>
              </Link>
            </div>
            <div 
              style={{
                fontWeight: activeTab === 'Set_Password' ? "bold" : undefined,
                width: "100%",
                paddingLeft: '20%',
                marginBottom: '1em'
              }}
              className='menu'
              onClick={() => {
                setActiveTab('Set_Password');
                clearAllStorageData();
              }}
            >
              <Link to='/setpassword'>
                <div 
                  style={Style.sidebarDiv, {
                    color: activeTab === 'Set_Password' ?'white': 'rgba(255,255,255,.5)'
                  }} 
                  className='flex'
                >
                  {'Set Password'}
                </div>
              </Link>
            </div>
            <div
                style={{
                  fontWeight: activeTab === 'Disable_MFA' ? "bold" : undefined,
                  width: "100%",
                  paddingLeft: '20%',
                  marginBottom: '1em'
                }}
                className='menu'
                onClick={() => {
                  setActiveTab('Disable_MFA');
                  clearAllStorageData();
                }}
            >
              <Link to='/disablemfa'>
                <div
                    style={Style.sidebarDiv, {
                      color: activeTab === 'Disable_MFA' ?'white': 'rgba(255,255,255,.5)'
                    }}
                    className='flex'
                >
                  {'Disable MFA'}
                </div>
              </Link>
            </div>
          </Accordion.Content>

        </Accordion>
      </div>
    </div>
  );
}

function mapStateToProps(state = {}, ownProps) {
  const fullName = getUserFullName(state);
  return {fullName: fullName};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(SidebarLink));
