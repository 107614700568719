export const LIMIT = 15;

export const VoucherType = Object.freeze({
  PREPAID_CODES: "Prepaid Codes",
  PREPAID_CODE: "Prepaid Code",
  DISCOUNT_CODES: "Discount Codes",
  USER_MGMT: "User Management"
})

export const SideBarName = Object.freeze({
  VIEW: "View",
  CREATE: "Create",
  SEARCH: "Search"
});

export const SideBarValue = Object.freeze({
  VIEW_PREPAID_VOUCHER: "VIEW_PREPAID_VOUCHER",
  CREATE_PREPAID_CODE: "CREATE_PREPAID_CODE",
  SEARCH_PREPAID_CODE: "SEARCH_PREPAID_CODE",
  VIEW_DISCOUNT_CODE: "VIEW_DISCOUNT_CODE",
  CREATE_DISCOUNT_CODE: "CREATE_DISCOUNT_CODE",
  SEARCH_DISCOUNT_CODE: "SEARCH_DISCOUNT_CODE",
  SUBSCRIPTION: "SUBSCRIPTION"
})

export const FilterValues = Object.freeze({
  STARTUP: "Startup",
  ESSENTIAL: "Essential",
  PROFESSIONAL: "Professional",
  PROFESSIONAL2: "Professional2",
  REDEEMED: "Redeemed",
  CREATED: "Created",
  EXPIRED: "Expired"
})

export const FilterOptions = Object.freeze([
  { key: 'Startup', text: 'Startup', value: 'Startup' },
  { key: 'Essential', text: 'Essential', value: 'Essential' },
  { key: 'Professional', text: 'Professional', value: 'Professional' },
  { key: 'Professional2', text: 'Professional Adv', value: 'Professional2' },
])

export const SortOptions = Object.freeze([
  { key: 'createdon', text: 'Created On', value: 'createdon' },
  { key: 'createdby', text: 'Created By', value: 'createdby' },
  { key: 'duration', text: 'Duration', value: 'duration' },
])

export const TableColumn = Object.freeze({
  Amount: 'amount',
  Code: "Code",
  Plan: "plan",
  Duration: "duration",
  Amount: "amount",
  CreatedFor: "createdFor",
  CreatedOn: "createdOn",
  CreatedBy: "createdBy",
  RedeemedBy: "redeemedBy"
})

export const PageSize = Object.freeze([
  { key: '5', text: '5', value: '5' },
  { key: '10', text: '10', value: '10' },
  { key: '15', text: '15', value: '15' },
])

export const PaymentOptions = Object.freeze([
  { key: 'inr', text: 'INR', value: 'inr' },
  { key: 'usd', text: 'USD', value: 'usd' }
])

export const PaymentGatewayOptions = Object.freeze([
  { key: 'cashfree', text: 'Cashfree', value: 'cashfree' },
  { key: 'razorpay', text: 'Razorpay', value: 'razorpay' },
  { key: 'stripe', text: 'Stripe', value: 'stripe' }
])

export const PaymentOptionValue = Object.freeze({
  Paid: "Paid",
  ToBePaid: "ToBePaid"
})

export const CouponType = Object.freeze({
  PREPAID: "Prepaid",
  DISCOUNT: "Discount"
})

export const PlanStatus = Object.freeze({
  STARTED: "Started",
  AVAILABLE: "Available",
  NOT_AVAILABLE: "Not Available"
})

export const PaymentStatus = Object.freeze({
  PAID: "Paid",
  UNPAID: "Unpaid"
})