import {gotoLoginPage} from "../api/utilities/NavigationUtils";

export class BaseException extends Error {
    code
    title

    constructor(code, title, message) {
        super(message);
        this.code = code
        this.title = title
    }
}

export function parseAxiosError(error) {
    if (error.message.toString() === "Network Error") {
        gotoLoginPage()
    } else {
        return getErrorResponse(error)
    }
}

export function getErrorResponse(error) {
    console.debug('API Error' + JSON.stringify(error))
    let errorModel
    try {
        const code = error.status || error.Code || error.code || -1
        const message =  error.message || error.Message || error.debugMessage || error.errorMessage
        if (!message || message.length === 0) {
            errorModel = getFallbackErrorMessageByCode(code)
        } else {
            errorModel = new BaseException(code, "Error", message)
        }
    } catch (e) {
        errorModel = getFallbackErrorMessageByCode(-1)
    }
    return {success: false, data: null, error: errorModel}
}

export function getFallbackErrorMessageByCode(code, title = "Error", message = "Something went wrong, Please try this again.") {
    switch (true) {
        case (code === 400):
            return new BaseException(400, "Bad Request", "A token or input might be amiss here. Try again.")
        case (code === 401):
            return new BaseException(401, "Unauthorized", "A token or input might be amiss here. Try again.")
        case (code === 404):
            return new BaseException(404, "Not Found", "You are looking for something that doesn't exist.")
        case (code === 408):
            return new BaseException(408, "Request Timeout", "We are sorry but that request timed out. Try again.")
        case (code === NETWORK_NOT_AVAILABLE):
            return new BaseException(code, "Network Error", "Network Not available.")
        case (code === PERMISSION_NOT_GRANTED):
            return new BaseException(code, "Don't have permission", "You need to grant permission from settings")
        case (code >= 500 && code <= 599):
            return new BaseException(code, "Internal Server Error", "Oh that's a strange one we haven't encountered before. Try again please.")
        default:
            return new BaseException(code, "Error", message ?? "Something went wrong, Please try this again.")
    }
}

export const NETWORK_NOT_AVAILABLE = 5000
export const PERMISSION_NOT_GRANTED = 5001
