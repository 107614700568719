import React, {useEffect, useState} from 'react';
import Style from '../../Style';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SidebarLink from "../sidebar/SidebarLink";

import {Sidebar, Segment, Divider, Grid, Accordion} from "semantic-ui-react";

import "./style.css";

import { DisplayCodeDetails } from '../common/DisplayCodeDetails';
import MainHeader from '../mainHeader/MainHeader';

function ViewVoucherDetails(props) {

    const [voucherDetails, setVoucherDetails] = useState(props.history.location.state.data);

    return (
        <div style={Style.mainDiv}>
            <SidebarLink/>
            <Sidebar.Pushable>
                <MainHeader />
                <div style={Style.dashboardDiv} className="font">
                    <Grid centered>
                        <Grid.Column tablet={16} computer={8}>
                            <Segment className="voucherDetailSection">
                                <h2 style={Style.centraliseTitle}>Prepaid Code Details</h2>
                                <Divider/>
                                <DisplayCodeDetails data={voucherDetails} />
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </div>
            </Sidebar.Pushable>
        </div>
    )
}

function mapStateToProps(state = {}, ownProps) {
    return{};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewVoucherDetails);