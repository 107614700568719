import { SubscriptionRepository } from "../../api/repo/SubscriptionRepository";

export class SearchSubscriptionPagePresenter {
    subscriptionRepo = new SubscriptionRepository;

    getTenantList(args) {
        this.subscriptionRepo.getTenantList(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

}