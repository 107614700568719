import {VoucherType} from "../constant/Constant";

export function clearAllStorageData() {
  localStorage.clear();
}

export function isLocalHost() {
  return window.location.href.indexOf("localhost") > -1;
}

export function getDateFormat(dateValue) {
  const options = { year: "numeric", month: "long", day: "numeric" }
  return new Date(dateValue).toLocaleDateString(undefined, options)
}

export function getVoucherType() {
  if (window.location.href.indexOf("view_prepaid_code") > -1 || window.location.href.indexOf("view") > -1) {
      return VoucherType.PREPAID_CODES;
  } else if (window.location.href.indexOf("create_prepaid_code") > -1) {
    return VoucherType.PREPAID_CODE
  }else {
      return VoucherType.DISCOUNT_CODES;
  }
}

export function formatExpiredDate(date, separator = "-") {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
      dd = "0" + dd;
  }
  if (mm < 10) {
      mm = "0" + mm;
  }
  return yyyy + separator + mm + separator + dd;
}

export function isValidEmail(email) {
    if (email.trim() === "") {
        return false;
    }

    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}
