import AxiosSharedInstance from '../AxiosSharedInstance';
import {requestHeaderForGET} from '../../utilities/InternetSettings';

export class AuthToken {
    static token;
}

export function getApiConfig() {
    return {
        credentials: 'include',
        mode: 'cors',
        withCredentials: true,
    };
}

export class BaseApiClient {

    instance;

    getApiVersion() {
        return 'v1/';
    }

    getBaseUrl() {
        return process.env.REACT_APP_BASE_URL;
    }

    getPath() {
        return '';
    }

    getFullBaseUrl() {
        return `${this.getBaseUrl()}${this.getApiVersion()}${this.getPath()}`;
    }

    constructor() {
        this.instance = AxiosSharedInstance.getInstance().getAxiosInstance();
    }

    getRequestConfig() {
        return {
            headers: requestHeaderForGET(),
            baseURL: this.getFullBaseUrl(),
        };
    }
}
