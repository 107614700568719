export function gotoLoginPage() {
    window.open(
        process.env.REACT_APP_LOGIN_URL + window.location.href,
        "_self"
    );
}

export function getQueryParamsFromURL(str) {
    if(str) {
        return JSON.parse('{"' + decodeURI(str.substring(1).replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}')
    }
}
