import {BaseApiClient, getApiConfig} from "./BaseApiClient";
import {requestHeaderForGET} from "../../utilities/InternetSettings";

export class SubscriptionApiClient extends BaseApiClient {

    getApiVersion() {
        return ""
    }

    async getTenantList(args) {
        return await this.instance.get(`v1/subengine/admin/tenants/${args.searchValue}`, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: null}
        });
    }

    async getOrganisationDetails(args) {
        return await this.instance.get(`v1/subengine/admin/sub/${args.tenantID}`, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: null}
        });
    }

    async applyPrepaidCode(args) {
        return await this.instance.post(`v1/subengine/admin/sub/prepaid`, args.request, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: error.response.data.errorMessage}
        });
    }

    async applyDiscountCode(args) {
        return await this.instance.post(`v1/subengine/admin/sub/discount`, args.request, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: error.response.data.errorMessage}
        });
    }

}