import { useEffect, useRef, useState } from 'react';
import Style from '../../Style';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SidebarLink from "../sidebar/SidebarLink";
import {handleError} from "../../utilities/AlertableErrorHandler";
import {useSnackbar} from "notistack";
import { useHistory } from "react-router-dom";

import { ViewSubscriptionPresenter } from './ViewSubscriptionPresenter';
import { getDateFormat, formatExpiredDate } from '../../utilities/CommonUtils';
import { CouponType, PlanStatus } from '../../constant/Constant';

import {Button, Sidebar, Segment, Input, Label, Popup, Form, Checkbox, Container, Grid, Accordion, List, Divider, Modal, Message, Dimmer, Loader, Icon} from "semantic-ui-react";
import { Link } from '@material-ui/core';

import "./style.css";
import MainHeader from '../mainHeader/MainHeader';

import { CreatePrepaidVoucherCode } from '../common/createNewPrepaidVoucher/CreatePrepaidVoucherCode';
import { RevokeConfirmationPopup } from '../common/RevokeConfirmationPopup';

function ViewSubscription(props) {
    const [orgDetails, setOrgDetails] = useState(undefined);
    const [loader, setLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [couponType, setCouponType] = useState("");
    const [voucherCode, setVoucherCode] = useState("");
    const [override, setOverride] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [buttonLoad, setButtonLoad] = useState(false);
    const [prepaidLength, setPrepaidLength] = useState(true);
    const [erpUser, setERPUser] = useState(0);
    const [crmUser, setCRMUser] = useState(0);
    const [peopleUser, setPeopleUser] = useState(0);
    const [aioUser, setAIOUser] = useState(0);
    const [openCreateNewPrepaidCodeModal, setOpenCreateNewPrepaidCodeModal] = useState(false);

    //Revoke Prepaid Code
    const [displayRevokePrepaidCodeModal, setDisplayRevokePrepaidCodeModal] = useState(false);
    const [displayConfirmMessage, setDisplayConfirmMessage] = useState(false);
    const [disabledCode, setDisabledCode] = useState(false);

    const presenter = useRef(new ViewSubscriptionPresenter);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        getDetails();
    }, [])

    useEffect(() => {
        if (orgDetails !== undefined) {
            setLoader(false);
        }
    }, [orgDetails])

    function getDetails() {
        presenter.current.getOrganisationDetails({
            tenantID: props.location.state.data,
            onSuccess: (response) => {
                setOrgDetails(response);
                if (response.PrepaidCoupons.length > 0) {
                    setPrepaidLength(false);
                    getUserCount(response);
                }
            },
            onError: (error) => {
                setLoader(false);
                handleError(error, enqueueSnackbar);
            }
        })
    }

    function getUserCount(data) {
        let erpUserArray = [];
        let crmUserArray = [];
        let pplUserArray = [];
        const sum = data.PrepaidCoupons.map(data=>
            data.Products.map(item => 
                {
                    if (item.Name === "ERP") {
                        erpUserArray.push(item.UserCount);
                    } else if (item.Name === "CRM") {
                        crmUserArray.push(item.UserCount);
                    } else {
                        pplUserArray.push(item.UserCount);
                    }

                    if (erpUserArray.length > 0) {
                        setERPUser(erpUserArray.reduce(getUserCountTotal()));
                    }

                    if (crmUserArray.length > 0) {
                        setCRMUser(crmUserArray.reduce(getUserCountTotal()));
                    }

                    if (pplUserArray.length > 0) {
                        setPeopleUser(pplUserArray.reduce(getUserCountTotal()));
                    }

                }
            )
        )
    }

    function getUserCountTotal() {
        const reducer = (accumulator, curr) => accumulator + curr;
        return reducer
    }

    const prepaidCodeDetails = (data) => (
        <Accordion activeIndex={data.length > 0 ? data._id : 0} exclusive={data.length >1 ? false : true} panels={data.map((item) => {
            return {
                key: item._id,
                title: {content:<span style={Style.wordWrap}>{item.Code}</span>},
                content: {content:prepaidDetails(item)},
            }
        })} />
    )

    const prepaidDetails = (data) => (
        <Grid columns={2} className="accordionDetails">
            <Grid.Row>
                <Grid.Column width={7}><b>Plan : </b></Grid.Column>
                <Grid.Column>{data.PlanName}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={7}><b>Products :</b></Grid.Column>
                <Grid.Column>
                    {data.Products !== undefined && data.Products.map((products) => (
                        <List bulleted key={products.Name}>
                            <List.Item>
                                {products.Name} &nbsp;: &nbsp; {products.UserCount} users
                            </List.Item>
                        </List>
                    ))}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={7}><b>Prepaid Duration:</b></Grid.Column>
                <Grid.Column>{data.PrepaidDuration} months</Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={7}><b>Redeemed Date:</b></Grid.Column>
                <Grid.Column>{getDateFormat(data.RedeemedDate)}</Grid.Column>
            </Grid.Row>
        </Grid>
    )

    const discountCodeDetails = (data) => (
        <Accordion activeIndex={data.length > 0 ? data._id : 0} exclusive={data.length >1 ? false : true} panels={data.map((item) => {
            return {
                key: item._id,
                title: item.Code,
                content: {content:discountDetails(item)},
            }
        })} />
    )

    const discountDetails = (data) => (
        <Grid columns={2} className="accordionDetails">
            {data.PercentOff !== 0 && 
                <Grid.Row>
                    <Grid.Column width={7}><b>Discount:</b></Grid.Column>
                    <Grid.Column>{data.PercentOff} %</Grid.Column>
            </Grid.Row>
            }
            {data.AmountOff !== 0 &&
                <Grid.Row>
                <Grid.Column width={7}><b>Amount off:</b></Grid.Column>
                <Grid.Column>{data.AmountOff}</Grid.Column>
            </Grid.Row>
            }
            <Grid.Row>
                <Grid.Column width={7}><b>Redeemed Date:</b></Grid.Column>
                <Grid.Column>{getDateFormat(data.RedeemedDate)}</Grid.Column>
            </Grid.Row>
        </Grid>
    )

    const productsDetails = (data, user) => (
        <Grid columns={2}>
            <Grid.Row>
                <Grid.Column width={8}><b>Plan: </b></Grid.Column>
                <Grid.Column>{data.Name !== "" ? data.Name : "-" }</Grid.Column>
            </Grid.Row>
            {!prepaidLength && 
                <Grid.Row>
                    <Grid.Column width={8}><b>User Count: </b></Grid.Column>
                    <Grid.Column>{user}</Grid.Column>
                </Grid.Row>
            }
            {orgDetails.PrepaidCoupons.length === 0 && 
                <Grid.Row>
                    <Grid.Column width={8}><b>Trial: </b></Grid.Column>
                    <Grid.Column>{getTrialStatus(data)}</Grid.Column>
                </Grid.Row>
            }
            {(prepaidLength && data.Name !== "") && <Grid.Row>
                <Grid.Column width={8}><b>Next Charge Date: </b></Grid.Column>
                <Grid.Column>{getDateFormat(data.NextChargeDate)}</Grid.Column>
            </Grid.Row>}
        </Grid>
    )

    function getTrialStatus(data) {
        if (data.InTrial) {
            return PlanStatus.STARTED
        } else {
            if (data.TrialOver) {
                return PlanStatus.NOT_AVAILABLE
            } else {
                return PlanStatus.AVAILABLE
            }
        }
    }

    const applyCoupon = () => (
        <Modal
            size='tiny'
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header> 
                <h3 style={Style.centraliseTitle}>Apply {couponType} Coupon</h3>
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field
                        required
                        control={Input}
                        label={<label>{couponType} Code</label>}
                        placeholder='Voucher Code'
                        value={voucherCode}
                        onChange={(e, data) => setVoucherCode(data.value)}
                    />
                    <Form.Field>
                        <Checkbox 
                            label={ 
                                couponType === CouponType.PREPAID ? 
                                <label>
                                    Override existing codes
                                    &nbsp;
                                    <Popup content='All existing codes applied will be removed if codes are of different plans' trigger={<Label circular>?</Label>} />
                                </label> : 
                                <label>
                                    Remove existing codes
                                    &nbsp;
                                    <Popup content='All existing discount codes will be removed and only the current code will be applied' trigger={<Label circular>?</Label>} />
                                </label>
                            }
                            checked={override}
                            onChange={()=>setOverride(!override)}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {couponType === CouponType.PREPAID &&
                    <Link style={{float:'left', paddingTop:'10px', cursor:'pointer'}} onClick={()=>openPaymentForm()}>Create new prepaid</Link>
                }
                <Button onClick={() => onClosePaymentForm()}>
                    Cancel
                </Button>
                <Button
                    color="blue"
                    content="Submit"
                    loading={buttonLoad}
                    onClick={() => {couponType === CouponType.PREPAID ? applyPrepaidCode() : applyDiscountCode()}}
                />
                {errorMsg && 
                    <Message negative>
                        <Message.Header>{errorMsg}</Message.Header>
                        {errorMsg === "Error getting coupon" && 
                            <p>Coupon is unpaid.</p>
                        }
                    </Message>
                }
            </Modal.Actions>
        </Modal>
    )

    function openPaymentForm() {
        setErrorMsg(null);
        setOpen(false);
        setOpenCreateNewPrepaidCodeModal(true);
    }

    function onClosePaymentForm() {
        setVoucherCode("");
        setOpen(false);
    }

    function applyPrepaidCode() {
        let request = {
            Code: voucherCode,
            Override: override,
            TenantID: orgDetails.TenantID,
            UserID: orgDetails.UserID
        }

        setButtonLoad(true);
        presenter.current.applyPrepaidCode({
            request,
            onSuccess: (response) => {
                setButtonLoad(false);
                setOverride(false);
                setVoucherCode("");
                getDetails();
                setOpen(false);
                setErrorMsg(null);
            },
            onError: (error) => {
                setButtonLoad(false);
                setErrorMsg(error);
            }
        })
    }

    function applyDiscountCode() {
        let request = {
            Code: voucherCode,
            ResetPrevious: override,
            TenantID: orgDetails.TenantID,
            UserID: orgDetails.UserID
        }

        setButtonLoad(true);
        presenter.current.applyDiscountCode({
            request,
            onSuccess: (response) => {
                setButtonLoad(false);
                setOverride(false);
                setVoucherCode("");
                getDetails();
                setOpen(false);
                setErrorMsg(null);
            },
            onError: (error) => {
                setButtonLoad(false);
                setErrorMsg(error);
            }
        })
    }

    const createPrepaidVoucher = () => (
        <Modal
            size='small'
            open={openCreateNewPrepaidCodeModal}
            onClose={() => setOpenCreateNewPrepaidCodeModal(false)}
            onOpen={() => setOpenCreateNewPrepaidCodeModal(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header style={Style.centraliseTitle}>
                Create a New Prepaid Code 
                <Icon style={{float:'right', cursor: 'pointer'}} name="close" onClick={ () => openPaymentFormModal()}/>
            </Modal.Header>
            <Modal.Content scrolling>
                <CreatePrepaidVoucherCode subscriptionPage={true} newVoucherCode={(data) => setVoucherCode(data)} orgDetails={orgDetails} email={props.location.state.email} onClick={() => openPaymentFormModal()}/>
            </Modal.Content>
            { errorMsg && <Message negative>
                <Message.Header>{errorMsg}</Message.Header>
            </Message>}
        </Modal>
    )

    function openPaymentFormModal() {
        setOpenCreateNewPrepaidCodeModal(false);
        setOpen(true);
    }

    const revokePrepaidCode = () => (
        <Modal
            size='tiny'
            open={displayRevokePrepaidCodeModal}
            onClose={() => setDisplayRevokePrepaidCodeModal(false)}
            onOpen={() => setDisplayRevokePrepaidCodeModal(true)}
            closeOnDimmerClick={false}
        >
            <Modal.Header style={Style.centraliseTitle}>
                Revoke Prepaid Code 
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field
                        required
                        control={Input}
                        label={<label>Prepaid Code</label>}
                        placeholder='Prepaid Code'
                        value={voucherCode}
                        onChange={(e, data) => setVoucherCode(data.value)}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {setDisplayRevokePrepaidCodeModal(false); setVoucherCode(""); setErrorMsg("")}}>
                    Cancel
                </Button>
                <Popup
                    trigger={<Button
                        color="red"
                        content="Revoke"
                        loading={buttonLoad}
                        disabled={voucherCode.trim().length !== 0 ? false : true}
                        onClick={() => isVoucherValid(false)}
                    />}
                    content="Code can be reused again."
                    position='bottom left'
                />
                <Popup
                    trigger={<Button
                        color="red"
                        content="Revoke & Disabled"
                        loading={buttonLoad}
                        disabled={voucherCode.trim().length !== 0 ? false : true}
                        onClick={() => {
                            setDisabledCode(true);
                            isVoucherValid(true);
                        }}
                    />}
                    content="Code will be revoked from this organisation and will not be able to used again."
                    position='bottom left'
                />
                {errorMsg && 
                    <Message negative>
                        <Message.Header>{errorMsg}</Message.Header>
                    </Message>
                }
            </Modal.Actions>
        </Modal>
    )

    function isVoucherValid(disabledVoucher){
        let codeDetails = orgDetails.PrepaidCoupons.filter(item => item.Code === voucherCode)
        let redeemDate = new Date(codeDetails[0].RedeemedDate)
        var expiredDate = new Date(redeemDate.setMonth(redeemDate.getMonth()+codeDetails[0].PrepaidDuration)-1);
        expiredDate.setDate(expiredDate.getDate() - 1);
        let todayDate = formatExpiredDate(new Date());

        let eDate = new Date(formatExpiredDate(expiredDate).toString());
        let today = new Date(todayDate.toString());
        
        if (eDate.getTime() < today.getTime()) {
            setErrorMsg("Voucher expired. Cannot be revoked.");
        } else {
            setDisplayConfirmMessage(true);
        }
    }

    const confirmPopup = () => 
        <RevokeConfirmationPopup
            openModal={displayConfirmMessage}
            updateModal={setDisplayConfirmMessage}
            voucherCode={voucherCode}
            organisationName={props.location.state.tenantName}
            buttonLoad={buttonLoad}
            callRevoke={()=>revokeCode()}
            disabled={disabledCode}
            tenantID={orgDetails.TenantID}
            removeSelectedTenant= {()=>null}
        />

    function revokeCode() {
        setDisplayRevokePrepaidCodeModal(false);
        setVoucherCode("");
        window.location.reload();
    }

    return (
        <div style={Style.mainDiv}>
            <SidebarLink/>
            <Sidebar.Pushable>
                <MainHeader/>
                <div style={Style.dashboardDiv} className="font">
                    <Grid className="mainGrid">
                        <Grid.Row>
                            <Segment.Group className="segmentGrpWidth">
                                <Segment>
                                    <Container>
                                        <h2 style={Style.centraliseTitle}>Subscription Details</h2>
                                    </Container>
                                </Segment>
                                <Segment>
                                    {!loader &&
                                        <Grid columns='equal'>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <b>Organisation:</b>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    {props.location.state.tenantName}
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <b>Email:</b>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <div style={Style.wordWrap}><span>{props.location.state.email}</span></div>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Divider/>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <b>Payment:</b> 
                                                </Grid.Column>
                                                <Grid.Column>
                                                    { orgDetails.CardAvailable ? "Card" : "No payment added"}
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}><b>Prepaid Code(s)</b></Grid.Column>
                                                <Grid.Column>
                                                    {orgDetails.PrepaidCoupons.length > 0 ? prepaidCodeDetails(orgDetails.PrepaidCoupons) : '-'}
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}><b>Discount Code(s)</b></Grid.Column>
                                                <Grid.Column>
                                                    {(orgDetails !== undefined && orgDetails.DiscountCoupons.length !== 0 ) ? discountCodeDetails(orgDetails.DiscountCoupons) : '-'}
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Divider/>
                                            {orgDetails.AIOPlan.Name === "" && <Container>
                                                <Grid columns={2}>
                                                    <Grid.Row>
                                                        <Grid.Column width={6}><b>Books Plan</b></Grid.Column>
                                                        <Grid.Column>{productsDetails(orgDetails.ERPPlan, erpUser)}</Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={6}><b>Sales Plan</b></Grid.Column>
                                                        <Grid.Column>{productsDetails(orgDetails.CRMPlan,crmUser)}</Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={6}><b>People Plan</b></Grid.Column>
                                                        <Grid.Column>{productsDetails(orgDetails.PeoplePlan, peopleUser)}</Grid.Column>
                                                    </Grid.Row>
                                                    <Divider />
                                                </Grid>
                                            </Container>}
                                            {orgDetails.AIOPlan.Name !== "" && <Container>
                                                <Grid columns={2}>
                                                    <Grid.Row>
                                                        <Grid.Column width={6}><b>All-In-One Plan</b></Grid.Column>
                                                        <Grid.Column>{productsDetails(orgDetails.AIOPlan, aioUser)}</Grid.Column>
                                                    </Grid.Row>
                                                    <Divider />
                                                </Grid>
                                            </Container>}
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Button className="actionButtons" color='blue' onClick={()=>{setOpen(true);setCouponType(CouponType.DISCOUNT)}}>Apply Discount Code</Button>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Button className="actionButtons" color='blue' onClick={()=>{setOpen(true);setCouponType(CouponType.PREPAID)}}>Apply Prepaid Code</Button>
                                                </Grid.Column>
                                                {orgDetails.PrepaidCoupons.length !== 0 &&
                                                    <Grid.Column>
                                                        <Button className="actionButtons" color='red' onClick={()=>{setDisplayRevokePrepaidCodeModal(true)}}>Revoke Prepaid Code</Button>
                                                    </Grid.Column>
                                                }
                                            </Grid.Row>
                                        </Grid>
                                    }
                                    {open && applyCoupon()}
                                    {openCreateNewPrepaidCodeModal && createPrepaidVoucher()}
                                    {displayRevokePrepaidCodeModal && revokePrepaidCode()}
                                    {displayConfirmMessage && confirmPopup()}
                                </Segment>
                            </Segment.Group>
                        </Grid.Row>
                    </Grid>
                </div>
            </Sidebar.Pushable>
            {loader && 
                <Dimmer active={loader}>
                    <Loader>Retrieving Information...</Loader>
                </Dimmer>
            }
        </div>
    )
}

function mapStateToProps(state = {}, ownProps) {
    return{};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubscription);