import { PrepaidCodeApiClient } from "../client/PrepaidCodeApiClient";

export class PrepaidCodeRepository {
    prepaidCodeApiClient = new PrepaidCodeApiClient;

    async getListOfPrepaidVoucher(args) {
        return await this.prepaidCodeApiClient.getListOfPrepaidVoucher(args);
    }

    async createPrepaidVoucher(args) {
        return await this.prepaidCodeApiClient.createPrepaidVoucher(args);
    }

    async searchData(args) {
        return await this.prepaidCodeApiClient.searchData(args);
    }

    async approvePayment(args) {
        return await this.prepaidCodeApiClient.approvePayment(args);
    }

    async getListOfTenants(args) {
        return await this.prepaidCodeApiClient.getListOfTenants();
    }

    async revokePrepaidCode(args) {
        return await this.prepaidCodeApiClient.revokePrepaidCode(args);
    }

}