import {BaseApiClient} from "./BaseApiClient";
import {requestHeaderForGET} from "../../utilities/InternetSettings";
import {parseAxiosError} from "../../exceptions/BaseException";
import {gotoLoginPage} from "../utilities/NavigationUtils";

export class AuthApiClient extends BaseApiClient {

    getApiVersion() {
        return ""
    }

    async checkLoginStatus() {
        return await this.instance.get(`v1/iam/auth/sign-in/login/status`, {
            headers: requestHeaderForGET(false),
            withCredentials: true,
            baseURL: this.getBaseUrl(),
            credentials: "include",
            mode: "cors",
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            gotoLoginPage()
        });
    }

    async checkAdminStatus() {
        return await this.instance.get('v1/subengine/admin/info', {
            headers: requestHeaderForGET(false),
            withCredentials: true,
            baseURL: this.getBaseUrl(),
            credentials: "include",
            mode: "cors",
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: error.response.data.errorMessage}
        })
    }

}
