import {useEffect, useState} from 'react';
import {Input, Button} from "semantic-ui-react";

export function SearchField(props) {

    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (props?.searchValue !== undefined) {
            setSearchValue(props?.searchValue);
        }
    },[props?.searchValue])

    function search() {
        props.getSearchValue(searchValue);
    }

    function clearSearchText() {
        setSearchValue("");
        props.getSearchValue("");
    }

    function getSearchLabel() {
        if (props?.email) {
            return "Email"
        } else {
            return "Search"
        }
    }

    return (
        <div>
            <label><b>{getSearchLabel()} </b></label>
            &nbsp;
            <Input 
                style={props?.email && {width:'45%'}}
                placeholder={props?.email ? 'Ex. aa@bb.com': 'Code...'}
                value={searchValue}
                onChange={(e, data) => setSearchValue(data.value)}
            />
            &nbsp;
            <Button color='blue' onClick={()=>search()}>Search</Button>
            <Button onClick={() => clearSearchText()}>Clear</Button>
        </div>
    )

}