import React, {useEffect, useRef, useState} from 'react';
import Style from '../../Style';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SidebarLink from "../sidebar/SidebarLink";
import {handleError} from "../../utilities/AlertableErrorHandler";
import {useSnackbar} from "notistack";

import {Button, Sidebar, Segment, Input, Grid, Message, Container, Modal, Icon, Checkbox, Popup, Label} from "semantic-ui-react";

import {SearchPrepaidCodePresenter} from "./SearchPrepaidCodePresenter";

import { DisplayCodeDetails } from '../common/DisplayCodeDetails';
import { SearchField } from '../common/SearchField';
import MainHeader from '../mainHeader/MainHeader';
import { ApprovePaymentForm } from '../common/ApprovePaymentForm';
import { ApproveMessage } from '../common/ApproveMessage';
import { ApplyPrepaidCodeModal } from '../common/ApplyPrepaidCodeModal';

function SearchPrepaidCode(props) {

    const [searchValue, setSearchValue] = useState("");
    const [voucherData, setVoucherData] = useState(undefined);
    const [openApprovedModal, setOpenApprovedModal] = useState(false);
    const [approvedMessage, setApprovedMessage] = useState("");
    const [openApprovedPaymentSuccessfulModal, setOpenApprovedPaymentSuccessfulModal] = useState(false);
    const [openApplyCodeModal, setOpenApplyCodeModal] = useState(false);
    const [tenantName, setTenantName] = useState();
    const [override, setOverride] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const presenter = useRef(new SearchPrepaidCodePresenter());
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (searchValue.length > 0 ) {
            search();
        } else {
            setVoucherData(undefined);
        }
    }, [searchValue])

    function search() {
        presenter.current.searchData({
            searchValue: searchValue.trim(),
            onSuccess: (response) => {
                setVoucherData(response);
                getTenantName(response);
            },
            onError: (error) => {
                handleError(error, enqueueSnackbar);
            }
        })
    }

    const approveFormModal = () => <ApprovePaymentForm 
        openModal={setOpenApprovedModal} 
        data={voucherData}
        onClick={(data) => approvePayment(voucherData.Code, data)}
    />

    function approvePayment(code, checkboxValue) {
        let request = {
            Code: code,
            ForceApprove :checkboxValue
        }

        presenter.current.approvePayment({
            request,
            onSuccess: (response) => {
                setApprovedMessage("");
                setOpenApprovedModal(false);
                setOpenApprovedPaymentSuccessfulModal(true);
            },
            onError: (error) => {
                setOpenApprovedModal(false);
                setApprovedMessage(error);
                setOpenApprovedPaymentSuccessfulModal(true)
            }
        })
    }

    const approvedPaymentModal = () => <ApproveMessage 
        approvedMessage={approvedMessage} 
        openApproveMessageModal={openApprovedPaymentSuccessfulModal} 
        updateModal={setOpenApprovedPaymentSuccessfulModal} 
        onClick={() => approvedMessage.length === 0 ? refreshTable() : setOpenApprovedPaymentSuccessfulModal(false)}
    />

    function refreshTable() {
        search();
        setOpenApprovedPaymentSuccessfulModal(false);
    }

    function getTenantName(data) {
        if (data.CreatedForEmail !== "") {
            presenter.current.getListOfTenants({
                searchValue: data.CreatedForEmail.trim(),
                onSuccess: (response) => {
                    let filterResult = response.filter((item) => item.TenantID === data.CreatedForTenantID);
                    if (filterResult.length > 0 ){
                        setTenantName(filterResult[0].TenantName)
                    } else {
                        setTenantName("-")
                    }
                },
                onError: (error) => {
                }
            })
        }
    }

    const applyPrepaidCodeModal = () => <ApplyPrepaidCodeModal
        openModal={openApplyCodeModal}
        updateOpenModal={(data)=>setOpenApplyCodeModal(data)}
        data={voucherData}
        tenantName={tenantName}
        overrideCheckbox={(data)=>  setOverride(data)}
        onSubmit={() => applyPrepaidCode()}
        errorMsg={errorMsg}
    />

    function applyPrepaidCode() {
        let request = {
            Code: voucherData.Code,
            Override: override,
            TenantID: voucherData.CreatedForTenantID,
            UserID: voucherData.CreatedForUserID
        }

        presenter.current.applyPrepaidCode({
            request,
            onSuccess: (response) => {
                setOpenApplyCodeModal(false);
                setErrorMsg(null);
                setOverride(false);
                updateTableData();
            },
            onError: (error) => {
                setOpenApplyCodeModal(true);
                setErrorMsg(error);
            }
        })
    }

    function updateTableData() {
        search();
    }

    return (
        <div style={Style.mainDiv}>
            <SidebarLink/>
            <Sidebar.Pushable>
                <MainHeader />
                <div style={Style.dashboardDiv} className="font">
                    <Grid style={{paddingLeft:"27%"}}>
                        <Grid.Row >
                            <Segment.Group style={{width:"63%"}}>
                                <Segment>
                                    <h2 style={Style.centraliseTitle}>
                                        Search Prepaid Codes
                                    </h2>
                                </Segment>
                                <Segment >
                                    <SearchField getSearchValue={(data) => setSearchValue(data)} />
                                    {voucherData !== undefined &&
                                        <div>
                                            {voucherData.Code !== "" ?
                                                <DisplayCodeDetails 
                                                    data={voucherData} 
                                                    searchPage={true} 
                                                    onClick={()=>setOpenApprovedModal(true)} 
                                                    applyPrepaidCode={() => setOpenApplyCodeModal(true)}
                                                    refreshPage={() => search()}
                                                /> :
                                                <Container style={{marginTop:'10px'}}>
                                                    <Message error>
                                                        <Message.Header>Prepaid code does not exist</Message.Header>
                                                        <p>Please enter a valid prepaid code.</p>
                                                    </Message>
                                                </Container>
                                            }
                                        </div>
                                    }
                                </Segment>
                            </Segment.Group>
                        </Grid.Row>
                    </Grid>
                    {openApprovedModal && approveFormModal()}
                    {openApprovedPaymentSuccessfulModal && approvedPaymentModal()}
                    {openApplyCodeModal && applyPrepaidCodeModal()}
                </div>
            </Sidebar.Pushable>
        </div>
    )
}

function mapStateToProps(state = {}, ownProps) {
    return{};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPrepaidCode);