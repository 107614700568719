import {BaseApiClient, getApiConfig} from "./BaseApiClient";
import {requestHeaderForGET} from "../../utilities/InternetSettings";
import {parseAxiosError} from "../../exceptions/BaseException";

export class PrepaidCodeApiClient extends BaseApiClient {

    getApiVersion() {
        return ""
    }

    async getListOfPrepaidVoucher(args) {
        return await this.instance.get(`v1/subengine/admin/pcode?sortBy=${args.sortBy}&sortOrder=dsc&pageOffset=${args.page}&pageSize=${args.pageSize}`, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: null}
        });
    }

    async createPrepaidVoucher(args) {
        return await this.instance.post(`v1/subengine/admin/pcode`, args.request, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: error.response.data.errorMessage}
        });
    }

    async searchData(args) {
        return await this.instance.get(`v1/subengine/admin/pcode/${args.searchValue}`, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: null}
        });
    }

    async approvePayment(args) {
        return await this.instance.post(`v1/subengine/admin/pcode-approve`, args.request, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: error.response.data.errorMessage}
        });
    }

    async getListOfTenants() {
        return await this.instance.get(`v1/users/tenants`, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: null}
        });
    }

    async revokePrepaidCode(args) {
        return await this.instance.post(`v1/subengine/admin/revoke`, args, {
            headers: requestHeaderForGET(),
            baseURL: process.env.REACT_APP_BASE_URL,
            ...getApiConfig()
        }).then(function (response) {
            return {success: true, data: response.data, error: null}
        }).catch(function (error) {
            return {success: false, data: error.data, error: error.response.data.errorMessage}
        });
    }

}