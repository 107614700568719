import { PrepaidCodeRepository } from "../../../api/repo/PrepaidCodeRepository";

export class CreatePrepaidVoucherPresenter {
    prepaidCodeRepo = new PrepaidCodeRepository;

    createPrepaidVoucher(args) {
        this.prepaidCodeRepo.createPrepaidVoucher(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

    approvePayment(args) {
        this.prepaidCodeRepo.approvePayment(args).then(response => {
            const {success, data, error} = response;
            if (success) {
                args.onSuccess && args.onSuccess(data)
            } else {
                args.onError && args.onError(error)
            }
        });
    }

}