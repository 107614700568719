import {UserApiClient} from "../client/UserApiClient";

export class UserRepository {
    userApiClient = new UserApiClient()

    async getUserDetails(id) {
        return await this.userApiClient.getUserDetails(id)
    }
    async disableMFAUserAccount(email) {
        return await this.userApiClient.disableMFAUserAccount(email)
    }
    async unLockUserAccount(email) {
        return await this.userApiClient.unLockUserAccount(email)
    }

    async verifyUserEmail(email) {
        return await this.userApiClient.adminEmailVerify(email)
    }

    async setUserPassword(payload) {
        return await this.userApiClient.setUserPassword(payload)
    }
}
